import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
const camelcaseObjectDeep = require('camelcase-object-deep')

export const $http = axios.create({
  baseURL: ''
})
$http.interceptors.request.use((config) => {
  const token = store.state.auth.accessToken
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
$http.interceptors.response.use((response) => {
  return response
}, async error => {
  const e = camelcaseObjectDeep(error)
  console.log(e.response.data)
  if (error.response.status === 403 || error.response.status === 401) {
    await store.dispatch('auth/logout')
  }
  return Promise.reject(error)
}
)

export default function install (Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return $http
    }
  })
}
