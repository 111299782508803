import axios from 'axios'
import applyConverters from 'axios-case-converter'
import store from '@/store'
import qs from 'qs'

const instance = applyConverters(axios.create({
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
}))

instance.interceptors.request.use(
  config => {
    const token = store.state.auth.accessToken
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)
instance.interceptors.response.use(
  response => {
    return response.data
  },
  async error => {
    if (error.response.status === 403 || error.response.status === 401) {
      await store.dispatch('auth/logout')
    }
    return Promise.reject(error)
  }
)

export default instance
