import { mapGetters, mapMutations } from 'vuex'
const PACKAGES = ['Basic', 'Premium', 'Premium+', 'Enterprise', 'BDTL']
const MOCKED_PACKAGES = [
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 1,
    has_social: false,
    callouts_count: 0,
    price: '0',
    name: 'Basic',
    translate_count: 0
  },
  {
    lifetime: 12,
    description:
      'Sign up or Upgrade to Premium and get the complete benefits (all the bells and whistles) of sayHola. It`s a small annual price to pay for those of us who value privacy.',
    language: 'en',
    id: 2,
    has_social: true,
    callouts_count: 50000,
    price: '29.99',
    name: 'Premium',
    translate_count: 30000
  },
  {
    lifetime: 24,
    description:
      'Complete Premium benefits (all the bells and whistles) of sayHola. It`s a small annual price to pay for those of us who value privacy and extra features.',
    language: 'en',
    id: 3,
    has_social: true,
    callouts_count: 100000,
    price: '72',
    name: 'Premium Plus',
    translate_count: 0
  },
  {
    lifetime: 12,
    description:
      'Available to the select professional market-makers that offer sayHola users a unique and powerful presentation of ideas.',
    language: 'en',
    id: 7,
    has_social: true,
    callouts_count: 0,
    price: '0.00',
    name: 'Influencer',
    translate_count: 50000
  },
  {
    lifetime: 12,
    description:
      'Sign up to Business Lite and access a strictly business-focused platform. Centered on clear, direct and private communications, without the need to see advertising or Social Space.',
    language: 'en',
    id: 4,
    has_social: true,
    callouts_count: 200000,
    price: '89.99',
    name: 'Business lite',
    translate_count: 160000
  },
  {
    lifetime: 12,
    description:
      'Sign up to Business Lite and access a strictly business-focused platform. Centered on clear, direct and private communications, without the need to see advertising or Social Space.',
    language: 'en',
    id: 5,
    has_social: true,
    callouts_count: 500000,
    price: '129.99',
    name: 'Business lite Plus',
    translate_count: 280000
  },
  {
    lifetime: 12,
    description:
      "SayHola Premium Services for Government, Education, Emergency Services, NGO's, and Medical Care Providers",
    language: 'en',
    id: 6,
    has_social: true,
    callouts_count: -1,
    price: '0.00',
    name: 'Non for Profit',
    translate_count: 0
  },
  {
    lifetime: 6,
    description:
      'Try Most of sayHola services before upgrading. When your happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 8,
    has_social: true,
    callouts_count: 0,
    price: '0',
    name: 'Basic Offer',
    translate_count: 0
  },
  {
    lifetime: 12,
    description:
      'Influencer package. Take advantage of powerful advertising management and organization tools for your business, as well as other tools for your successful start.',
    language: 'en',
    id: 9,
    has_social: true,
    callouts_count: 0,
    price: '0.00',
    name: 'BDTL Agent',
    translate_count: 50000
  },
  {
    lifetime: 12,
    description:
      'Sign up or Upgrade to Premium and get the complete benefits (all the bells and whistles) of sayHola. It`s a small annual price to pay for those of us who value privacy.',
    language: 'en',
    id: 10,
    has_social: true,
    callouts_count: 50000,
    price: '600',
    name: 'Campaign New User',
    translate_count: 30000
  },
  {
    lifetime: 12,
    description:
      'With us, you get to build your own Advertising Agency, Sponsor a network of Affiliates, and at the same time expand your bottom line - that\u2019s right, earn from your downline.',
    language: 'en',
    id: 11,
    has_social: true,
    callouts_count: 1000000,
    price: '289.99',
    name: 'BDTL Agency',
    translate_count: 3500000
  },
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 49,
    has_social: true,
    callouts_count: 10000,
    price: '0',
    name: 'RSN Influencer',
    translate_count: -1
  },
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 50,
    has_social: true,
    callouts_count: 100000,
    price: '199.99',
    name: 'Premium+',
    translate_count: -1
  },
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 51,
    has_social: true,
    callouts_count: 1000000,
    price: '299.99',
    name: 'Enterprise',
    translate_count: -1
  },
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 52,
    has_social: true,
    callouts_count: 100000,
    price: '199.99',
    name: 'BDTL',
    translate_count: -1
  },
  {
    lifetime: 12,
    description:
      'Need to try sayHola before deciding on a package? Select this package and we will give you 7 days of Premium service. When you are happy, upgrade to Premium sayHola and privatize your communications with all the extras.',
    language: 'en',
    id: 53,
    has_social: true,
    callouts_count: 1000000,
    price: '299.99',
    name: 'BDTL+',
    translate_count: -1
  }
]

export default {
  name: 'packages',
  data () {
    return {
      currentLocale: 'en',
      currentVideoObject: {},
      videoList: [
        {
          id: 0,
          key: 'secure',
          label: this.$t('packagesPage.holaSecure'),
          comingSoon: false
        },
        {
          id: 1,
          key: 'encryption',
          label: this.$t('packagesPage.encryption'),
          comingSoon: false
        },
        {
          id: 2,
          key: 'freelancer',
          label: this.$t('packagesPage.freelancer'),
          comingSoon: false
        },
        {
          id: 3,
          key: 'secretChat',
          label: this.$t('packagesPage.secretChats'),
          comingSoon: false
        },
        {
          id: 4,
          key: 'invisibility',
          label: this.$t('packagesPage.invisibility'),
          comingSoon: false
        },
        {
          id: 5,
          key: 'howToRegister',
          label: this.$t('packagesPage.howToRegister'),
          comingSoon: false
        },
        {
          id: 6,
          key: 'pinEntry',
          label: this.$t('packagesPage.pinEntry'),
          comingSoon: false
        },
        {
          id: 7,
          key: 'freelancerRegistration',
          label: this.$t('packagesPage.freelancerRegistration'),
          comingSoon: false
        },
        {
          id: 8,
          key: 'chatFeatures',
          label: this.$t('packagesPage.chatFeatures'),
          comingSoon: false
        },
        {
          id: 9,
          key: 'together',
          label: this.$t('packagesPage.together'),
          comingSoon: false
        }
      ],
      videoArray: [
        {
          id: 0,
          locale: 'en',
          secure: 'D1HycoUE23U',
          encryption: 'MBBkaRIcVs4',
          freelancer: 'wL6VHYwfoDo',
          secretChat: 'LX5T8RHvlHs',
          invisibility: 'QIi10zVMFco',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'LWGkmvi_PRw',
          pinEntry: '8KBkDWL7s2M',
          freelancerRegistration: 'zIolKskIukg',
          chatFeatures: '1l_Z_XCV81Q',
          together: 'qCTFyqh4oOc'
        },
        {
          id: 1,
          locale: 'es',
          secure: 'meXrB-EGgB8',
          encryption: '_CBKJsnMcf8',
          freelancer: '-YoZhQhyrRs',
          secretChat: 'ipMlK46iHP4',
          invisibility: 'GqmqIO-un5g',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'eYc2OXldCvg',
          pinEntry: 'UOgHzwYJYBc',
          freelancerRegistration: '6ak43xCzDOI',
          chatFeatures: 'pN5yycqOSkM',
          together: 'ZKl8rZF_sDA'
        },
        {
          id: 2,
          locale: 'ru',
          secure: 'qMf_30SZnRM',
          encryption: '8GZLtdtlgx4',
          freelancer: 'uJb0lRXk6Q8',
          secretChat: 'ReM2xBwIAEw',
          invisibility: 'hqQgP9h_rNY',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'WbN6ARzA0dU',
          pinEntry: 'v_OZAqPHqno',
          freelancerRegistration: '2J4I0OFyEpE',
          chatFeatures: 'YvSBbvdsW_g',
          together: 'CsaoxBnj6so'
        },
        {
          id: 3,
          locale: 'zh',
          secure: 'LTjVExvoOlk',
          encryption: 'eVeix20IdCI',
          freelancer: 'TOVYqInC7s0',
          secretChat: 'Ye4IqPmhkjg',
          invisibility: 'TMDQuCjmqxU',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'W8K67c5bH5o',
          pinEntry: 'S4nptSbwClI',
          freelancerRegistration: '1IqaxaX8k88',
          chatFeatures: 'R4LQmzFSI_Y',
          together: 'S7vNBAzmPWA'
        },
        {
          id: 4,
          locale: 'pt',
          secure: 'b-WNtdOTmok',
          encryption: '_xDksZ-eDoQ',
          freelancer: 'YrumT4-bJ9g',
          secretChat: 'NUjiq1aqblc',
          invisibility: 'zpbrZlehFpc',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'LYErS9Ut35c',
          pinEntry: 'shzwILZidm0',
          freelancerRegistration: 'cbdUlwNbw3A',
          chatFeatures: 'T1MRJmVPGzA',
          together: '5ydW8I7dgm4'
        },
        {
          id: 5,
          locale: 'fr',
          secure: 'kfNr4jSSuoQ',
          encryption: 'ScKEQI86uNw',
          freelancer: '_luJSvT9nCU',
          secretChat: 'YZFU8WEaKt4',
          invisibility: '4GNYLl1Drn4',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'OOxccULj5jE',
          pinEntry: 'fblp7lQa8x0',
          freelancerRegistration: 'uM43CqagcbA',
          chatFeatures: 'QEF0YDrCfbw',
          together: 'sgcy2dt61EQ'
        },
        {
          id: 6,
          locale: 'de',
          secure: 'XvjASjaB920',
          encryption: 'c8C93SSpJSM',
          freelancer: 's3egs7SaJ4U',
          secretChat: '9B9hQlWkSPE',
          invisibility: 'V4nUgVHqmCs',
          socialSpace: null,
          campaigns: null,
          howToRegister: 'EqkEZDDxAZ4',
          pinEntry: 'LcgQhs2XJHo',
          freelancerRegistration: 'VaoAKV9Nfdk',
          chatFeatures: 'JfJ8vu8lW2M',
          together: 'H-qK5d19GM0'
        },
        {
          id: 7,
          locale: 'jp',
          secure: 't78pJsZ7VVY',
          encryption: 'MroxmCXBCng',
          freelancer: 'pEFUypyyMNk',
          secretChat: 'RfwJGqNLPr0',
          invisibility: 'KSwsPMv2cpc',
          socialSpace: null,
          campaigns: null,
          howToRegister: '4g9M6PjWFic',
          pinEntry: 'oAwrwUgLFx4',
          freelancerRegistration: 'ctkIGTqV4NA',
          chatFeatures: 'vYglXVVJBN0',
          together: '_xr4aPnLaXA'
        }
      ]
    }
  },
  packages: PACKAGES,
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.packages')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      videoPopupId: 'packages/videoPopup'
    }),
    ...mapGetters([
      'currentLanguage'
    ]),
    features () {
      return [
        {
          name: 'verifiedBadge',
          description: false,
          availability: ['circle', 'check', 'check', 'check', 'check']
        },
        {
          name: 'secretChat',
          description: false,
          video: this.$t('packages.featureVideo.secretChats'),
          availability: ['check', 'check', 'check', 'check', 'check']
        },
        {
          name: 'encryption',
          description: false,
          video: this.$t('packages.featureVideo.encryption'),
          availability: ['check', 'check', 'check', 'check', 'check']
        },
        {
          name: 'calls',
          description: false,
          availability: ['check', 'check', 'check', 'check', 'check']
        },
        {
          name: 'freelancerProgram',
          video: this.$t('packages.featureVideo.freelancerProgram'),
          availability: ['check', 'check', 'check', 'check', 'check']
        },
        {
          name: 'blockchainWallet',
          blog: this.$t('blogList.blockchainWallet'),
          availability: ['check', 'check', 'check', 'check', 'check']
        },
        {
          name: 'conferenceCalls',
          description: false,
          availability: [`circle ${this.$t('trial')}`, 'check', 'check', 'check', 'check']
        },
        {
          name: 'rsnSocial',
          description: false,
          availability: [
            `circle ${this.$t('socialOnly')}`,
            `circle ${this.$t('socialBlogOnly')}`,
            'check',
            'check',
            'check'
          ]
        },
        {
          name: 'groups',
          description: false,
          availability: [`circle ${this.$t('groupsOnly')}`, 'check', 'check', 'check', 'check']
        },
        {
          name: 'syncAppConnection',
          description: false,
          availability: [`circle ${this.$t('oneOnly')}`, 'check', 'check', 'check', 'check']
        },
        {
          name: 'syncNumberConnection',
          description: false,
          blog: this.$t('blogList.syncNumberConnection'),
          availability: [`circle ${this.$t('oneOnly')}`, 'check', 'check', 'check', 'check']
        },
        {
          name: 'translations',
          description: false,
          availability: ['1-on-1', '1-on-1', this.$t('auto'), this.$t('auto'), this.$t('auto')]
        },
        {
          name: 'invisibility',
          description: false,
          video: this.$t('packages.featureVideo.invisibility'),
          availability: [`circle ${this.$t('trial')}`, 'check', 'check', 'check', 'check']
        },
        {
          name: 'assistantBot',
          description: false,
          blog: this.$t('blogList.holaPersonalAssistantBot'),
          availability: [this.$t('limited'), 'check', 'check', 'check', 'check']
        },
        {
          name: 'bots',
          description: false,
          blog: this.$t('blogList.bots'),
          availability: [
            'circle',
            `15% ${this.$t('commision')}`,
            `10% ${this.$t('commision')}`,
            `5%-10% ${this.$t('commision')}`,
            'circle'
          ]
        },
        {
          name: 'constructorBot',
          description: false,
          availability: ['circle', this.$t('limited'), this.$t('unlimited'), this.$t('unlimited'), 'circle']
        },
        {
          name: 'marketplaceDirectory',
          description: false,
          availability: ['circle', 'check', 'check', 'check', 'circle']
        },
        {
          name: 'campaigns',
          description: false,
          blog: this.$t('blogList.campaigns'),
          availability: [
            'circle',
            this.$t('weekleOffers'),
            this.$t('weekleOffers'),
            this.$t('weeklePlusPromium'),
            'circle'
          ]
        },
        {
          name: 'rewardsProgram',
          description: false,
          blog: this.$t('blogList.rewardsProgram'),
          availability: ['circle', 'check', 'check', 'check', 'check']
        },
        {
          name: 'resaleProgram',
          description: false,
          blog: this.$t('blogList.resaleProgram'),
          availability: [
            'circle',
            'circle',
            'circle',
            'circle',
            `% ${this.$t('commision')}`
          ]
        },
        {
          name: 'tools',
          description: false,
          blog: this.$t('blogList.tools'),
          availability: ['circle', 'check', 'check', 'check', 'check']
        }
      ]
    },
    packageListDisplay () {
      const list = []
      PACKAGES.filter(packageName => {
        if (this.allPackagesList.findIndex(pkg => pkg.name === packageName) > -1) {
          list.push(this.allPackagesList.find(pkg => pkg.name === packageName))
        }
      })
      return list
    },
    allPackagesList () {
      return MOCKED_PACKAGES
    }
  },
  created () {
    this.currentLocale = this.currentLanguage.urlPrefix

    for (const i in this.videoArray) {
      if (this.videoArray[i].locale === this.currentLocale) {
        this.currentVideoObject = this.videoArray[i]
      }
    }
  },
  methods: {
    ...mapMutations({
      changeShowVideoPopup: 'packages/CHANGE_SHOW_VIDEO_POPUP',
      changeVideoPopupInfo: 'packages/CHANGE_VIDEO_POPUP_INFO'
    }),
    showVideoPopup (key) {
      if (this.currentVideoObject[key]) {
        this.changeVideoPopupInfo({
          videoId: this.currentVideoObject[key]
        })
        setTimeout(() => {
          this.changeShowVideoPopup(true)
        }, 150)
      }
    },
    getFeatureTooltip (feature) {
      if (!feature.description) return
      const options = {
        content: `<div class="${this.$style.tooltipTitle}">${this.$t('packages.feature_' + feature.name)}</div>
          <div>${this.$t('packages.featureDescription_' + feature.name)}</div>`,
        classes: ['tooltip--light', this.$style.tooltip],
        html: true,
        offset: 10,
        trigger: 'hover focus'
      }
      if (window.innerWidth > 480) {
        options.placement = 'right-start'
      }
      return options
    },
    openVideo (feature) {
      if (!feature.commingSoon) {
        this.$modal.show('video', { src: feature.video, isYoutube: true })
      }
    },
    openBlog (item) {
      if (item.blog) {
        window.open(item.blog, '_blank')
      }
    }
  }
}
