import api from '@/api/matrix'
import camelcaseKeysDeep from 'camelcase-keys-deep'

const state = {
  matrixCredentials: null,
  isLoading: false
}

const getters = {
  matrixCredentials: state => state.matrixCredentials,
  isLoading: state => state.isLoading
}

const actions = {
  async getMatrixCredentials ({ commit }) {
    try {
      commit('CHANGE_LOADING', true)
      const { data } = await api.getMatrixCredentials()
      commit('SET_MATRIX_CREDENTIALS', data)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  async saveMatrixCredentials ({ commit }, payload) {
    try {
      commit('CHANGE_LOADING', true)
      await api.saveMatrixCredentials(payload)
      commit('SET_MATRIX_CREDENTIALS', payload)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  },
  async updateMatrixCredentials ({ commit }, payload) {
    try {
      commit('CHANGE_LOADING', true)
      await api.updateMatrixCredentials(payload)
      commit('SET_MATRIX_CREDENTIALS', payload)
    } finally {
      commit('CHANGE_LOADING', false)
    }
  }
}

const mutations = {
  SET_MATRIX_CREDENTIALS (state, data) {
    if (data) {
      if (state.matrixCredentials) {
        Object.assign(state.matrixCredentials, camelcaseKeysDeep(data))
      } else {
        state.matrixCredentials = data
      }
    } else {
      state.matrixCredentials = null
    }
  },
  CHANGE_LOADING (state, status) {
    state.isLoading = status
  }
}

export default { state, getters, actions, mutations }
