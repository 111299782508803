import ChatUserStep from './components/ChatUserStep.vue'
import SocialSPaceUserStep from './components/SocialSpaceUserStep.vue'
import ResultStep from './components/ResultStep.vue'
import JobsCategoryItem from './components/JobsCategoryItem.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'jobs',
  data () {
    return {
      currentActiveTab: 'withoutExperience',
      // tab 1
      itemsListWithoutExperience: [
        {
          id: 0,
          category: this.$t('adminPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan13')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan14')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan15')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan19')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan20')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan21')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan22')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan23'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan24')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan25')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan29')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan30'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan31')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan32')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan33')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan34')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan35')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan36')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan37'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan38')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan39')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan40')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan41')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan42'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan43')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan44')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan45')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan46')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan47')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan48')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan49'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan50')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan51')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan52')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan53')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan54')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan55')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan56'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan57')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan58')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan59')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan60')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan61')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan62')
                }
              ]
            },
            {
              id: 9,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan63'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan64')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan65')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan66')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan67')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan68')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan69')
                }
              ]
            },
            {
              id: 10,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan70'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan71')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan72')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan73')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan74')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan75')
                }
              ]
            },
            {
              id: 11,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan76'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan77')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan78')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan79')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan80')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan81')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan82')
                },
                {
                  id: 3,
                  content: this.$t('adminPositionWithoutExperienceSlogan83')
                }
              ]
            },
            {
              id: 12,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan84'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan85')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan86')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan87')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan88')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan89')
                }
              ]
            },
            {
              id: 13,
              itemTitle: this.$t('adminPositionWithoutExperienceSlogan90'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan91')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithoutExperienceSlogan92')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithoutExperienceSlogan93')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithoutExperienceSlogan94')
                }
              ]
            }
          ]
        },
        {
          id: 1,
          category: this.$t('educationPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('educationPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithoutExperienceSlogan8')
                },
                {
                  id: 3,
                  content: this.$t('educationPositionWithoutExperienceSlogan9')
                }
              ]
            }
          ]
        },
        {
          id: 2,
          category: this.$t('supportPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan13')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan14')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan15')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan19')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan20')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan21')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan22')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan23'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan24')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan25')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan27')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan28'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan29')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan30')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan31')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan32')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan33'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan34')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan35')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan36')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan37')
                },
                {
                  id: 3,
                  content: this.$t('supportPositionWithoutExperienceSlogan38')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan39'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan40')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan41')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan42')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan43')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan44')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan45')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan46'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan47')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan48')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan49')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan50')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('supportPositionWithoutExperienceSlogan51'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan52')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan53')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan54')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithoutExperienceSlogan55')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithoutExperienceSlogan56')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithoutExperienceSlogan57')
                },
                {
                  id: 3,
                  content: this.$t('supportPositionWithoutExperienceSlogan58')
                }
              ]
            }
          ]
        },
        {
          id: 3,
          category: this.$t('trainingPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('trainingPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('trainingPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan13')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan14')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan15')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('trainingPositionWithoutExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan19')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan20')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan21')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan22')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('trainingPositionWithoutExperienceSlogan23'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan24')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan25')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan29')
                },
                {
                  id: 3,
                  content: this.$t('trainingPositionWithoutExperienceSlogan30')
                },
                {
                  id: 4,
                  content: this.$t('trainingPositionWithoutExperienceSlogan31')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('trainingPositionWithoutExperienceSlogan32'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan33')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan34')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan35')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithoutExperienceSlogan36')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithoutExperienceSlogan37')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithoutExperienceSlogan38')
                }
              ]
            }
          ]
        },
        {
          id: 4,
          category: this.$t('facilitiesPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan13')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan14')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan15'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan16')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan17')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan18')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan19')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan20')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan21')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan22'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan23')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan24')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan25')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan26')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan27')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan28'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan29')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan30')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan31')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan32')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan33')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan34'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan35')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan36')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan37')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan38')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan39')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan40')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan41'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan42')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan43')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan44')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan45')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan46')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('facilitiesPositionWithoutExperienceSlogan47'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan48')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan49')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan50')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan51')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithoutExperienceSlogan52')
                }
              ]
            }
          ]
        },
        {
          id: 5,
          category: this.$t('logisticsPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan320'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan321')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan322')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan323')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan324')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan13')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan14'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan15')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan16')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan17')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan18')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan19')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan20')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan21'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan22')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan23')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan24')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan25')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan26'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan29')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan30')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan31')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan32')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan33'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan34')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan35')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan36')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan37')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan38')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan39')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan40'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan41')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan42')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan43')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan44')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan45')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan46'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan47')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan48')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan49')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan50')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan51')
                }
              ]
            },
            {
              id: 9,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan52'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan53')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan54')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan55')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan56')
                }
              ]
            },
            {
              id: 10,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan57'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan58')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan59')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan60')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan61')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan62')
                }
              ]
            },
            {
              id: 11,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan63'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan64')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan65')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan66')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan67')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan68')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan69')
                },
                {
                  id: 3,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan70')
                }
              ]
            },
            {
              id: 12,
              itemTitle: this.$t('logisticsPositionWithoutExperienceSlogan71'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan72')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan73')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan74')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan75')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan76')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithoutExperienceSlogan77')
                }
              ]
            }
          ]
        },
        {
          id: 6,
          category: this.$t('hospitalityPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan12')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan13')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan14')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan15')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan19')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan20')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan21')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan22')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan23'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan24')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan25')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan29')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan30'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan31')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan32')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan33')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan34')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan35')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan36')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan37'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan38')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan39')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan40')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan41')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan42')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan43')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan44'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan45')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan46')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan47')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan48')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan49')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan50')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan51'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan52')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan53')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan54')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan55')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan56'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan57')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan58')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan59')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan60')
                }
              ]
            },
            {
              id: 9,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan61'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan62')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan63')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan64')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan65')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan66')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan67')
                }
              ]
            },
            {
              id: 10,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan68'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan69')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan70')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan71')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan72')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan73')
                }
              ]
            },
            {
              id: 11,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan74'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan75')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan76')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan77')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan78')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan79')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan80')
                }
              ]
            },
            {
              id: 12,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan81'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan82')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan83')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan84')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan85')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan86')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan87')
                }
              ]
            },
            {
              id: 13,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan88'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan89')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan90')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan91')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan92')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan93')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan94')
                },
                {
                  id: 3,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan95')
                },
                {
                  id: 4,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan96')
                }
              ]
            },
            {
              id: 14,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan97'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan98')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan99')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan100')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan101')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan102')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan103')
                }
              ]
            },
            {
              id: 15,
              itemTitle: this.$t('hospitalityPositionWithoutExperienceSlogan104'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan105')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan106')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan107')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan108')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan109')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithoutExperienceSlogan110')
                }
              ]
            }
          ]
        },
        {
          id: 7,
          category: this.$t('miscellaneousPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan6')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan7')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan8')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan9'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan10')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan11')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan12')
                },
                {
                  id: 3,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan13')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan14')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan15')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan19')
                },
                {
                  id: 3,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan20')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan21')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan22')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan23'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan24')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan25')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan29')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan30'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan31')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan32')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan33')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan34')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan35')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan36')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan37'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan38')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan39')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan40')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan41')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan42')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan43'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan44')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan45')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan46')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan47')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan48')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan49')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan50'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan51')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan52')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan53')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan54')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan55')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan56')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('miscellaneousPositionWithoutExperienceSlogan57'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan58')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan59')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan60')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan61')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan62')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithoutExperienceSlogan63')
                }
              ]
            },
            {
              id: 9,
              itemTitle: this.$t('jobPosition1.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition1.slogan5')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition1.slogan6')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition1.slogan7')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition1.slogan2')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition1.slogan3')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition1.slogan4')
                }
              ]
            },
            {
              id: 10,
              itemTitle: this.$t('jobPosition2.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition2.slogan6')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition2.slogan7')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition2.slogan8')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition2.slogan3')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition2.slogan4')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition2.slogan5')
                }
              ]
            },
            {
              id: 11,
              itemTitle: this.$t('jobPosition3.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition3.slogan5')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition3.slogan6')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition3.slogan7')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition3.slogan2')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition3.slogan3')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition3.slogan4')
                }
              ]
            },
            {
              id: 12,
              itemTitle: this.$t('jobPosition4.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition4.slogan6')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition4.slogan7')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition4.slogan8')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition4.slogan2')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition4.slogan3')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition4.slogan4')
                }
              ]
            },
            {
              id: 13,
              itemTitle: this.$t('jobPosition5.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition5.slogan6')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition5.slogan7')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition5.slogan8')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition5.slogan2')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition5.slogan3')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition5.slogan4')
                }
              ]
            },
            {
              id: 14,
              itemTitle: this.$t('jobPosition6.slogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('jobPosition6.slogan5')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition6.slogan6')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition6.slogan7')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('jobPosition6.slogan2')
                },
                {
                  id: 1,
                  content: this.$t('jobPosition6.slogan3')
                },
                {
                  id: 2,
                  content: this.$t('jobPosition6.slogan4')
                }
              ]
            }
          ]
        }
      ],
      // tab2
      itemsListWithExperience: [
        {
          id: 0,
          category: this.$t('adminPositionWithExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('adminPositionWithExperienceSlogan2'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan3')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan4')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan5')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan6')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('adminPositionWithExperienceSlogan7'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan8')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan9')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan10')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan11')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan12')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan13')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('adminPositionWithExperienceSlogan14'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan15')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan16')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan17')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan18')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan19')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan20')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('adminPositionWithExperienceSlogan21'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan22')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan23')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan24')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan25')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('adminPositionWithExperienceSlogan26'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan29')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan30')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan31')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan32')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('adminPositionWithExperienceSlogan33'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan34')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan35')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan36')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan37')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan38')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan39')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('adminPositionWithExperienceSlogan40'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan41')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan42')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan43')
                },
                {
                  id: 3,
                  content: this.$t('adminPositionWithExperienceSlogan44')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan45')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan46')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan47')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('adminPositionWithExperienceSlogan48'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan49')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan50')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan51')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('adminPositionWithExperienceSlogan52')
                },
                {
                  id: 1,
                  content: this.$t('adminPositionWithExperienceSlogan53')
                },
                {
                  id: 2,
                  content: this.$t('adminPositionWithExperienceSlogan55')
                }
              ]
            }
          ]
        },
        {
          id: 1,
          category: this.$t('educationPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('educationPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan6')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('educationPositionWithExperienceSlogan7'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan8')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan9')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan10')
                },
                {
                  id: 3,
                  content: this.$t('educationPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan13')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan14')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('educationPositionWithExperienceSlogan15'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan16')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan17')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan18')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan19')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan20')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan21')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('educationPositionWithExperienceSlogan22'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan23')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan24')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan25')
                },
                {
                  id: 3,
                  content: this.$t('educationPositionWithExperienceSlogan26')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('educationPositionWithExperienceSlogan27')
                },
                {
                  id: 1,
                  content: this.$t('educationPositionWithExperienceSlogan28')
                },
                {
                  id: 2,
                  content: this.$t('educationPositionWithExperienceSlogan29')
                },
                {
                  id: 3,
                  content: this.$t('educationPositionWithExperienceSlogan30')
                }
              ]
            }
          ]
        },
        {
          id: 2,
          category: this.$t('supportPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('supportPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan6')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan7')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('supportPositionWithExperienceSlogan8'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan9')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan10')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan13')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan14')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('supportPositionWithExperienceSlogan15'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan16')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan17')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan18')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('supportPositionWithExperienceSlogan19')
                },
                {
                  id: 1,
                  content: this.$t('supportPositionWithExperienceSlogan20')
                },
                {
                  id: 2,
                  content: this.$t('supportPositionWithExperienceSlogan21')
                },
                {
                  id: 3,
                  content: this.$t('supportPositionWithExperienceSlogan22')
                }
              ]
            }
          ]
        },
        {
          id: 3,
          category: this.$t('trainingPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan6')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan7')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan8'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan9')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan10')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan13')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan14')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan15'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan16')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan17')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan18')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan19')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan20')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan21')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan22'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan23')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan24')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan25')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan26')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan27')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan28')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan29'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan30')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan31')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan32')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan33')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan34')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan35')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan36'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan37')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan38')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan39')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan40')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan41')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan42')
                }
              ]
            },
            {
              id: 6,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan43'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan44')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan45')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan46')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan47')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan48')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan49')
                }
              ]
            },
            {
              id: 7,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan50'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan51')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan52')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan53')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan54')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan55')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan56')
                }
              ]
            },
            {
              id: 8,
              itemTitle: this.$t('trainingPositionWithExperienceSlogan57'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan58')
                },
                {
                  id: 1,
                  content: this.$t('trainingPositionWithExperienceSlogan59')
                },
                {
                  id: 2,
                  content: this.$t('trainingPositionWithExperienceSlogan60')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('trainingPositionWithExperienceSlogan61')
                }
              ]
            }
          ]
        },
        {
          id: 4,
          category: this.$t('facilitiesPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan6')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan7')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan8'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan9')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan10')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan13')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan14'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan15')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan16')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan17')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan18')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan19')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan20')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan21'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan22')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan23')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan24')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan25')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan26')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan27')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan28'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan29')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan30')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan31')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan32')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan33')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan34')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('facilitiesPositionWithExperienceSlogan35'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan36')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan37')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan38')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('facilitiesPositionWithExperienceSlogan39')
                },
                {
                  id: 1,
                  content: this.$t('facilitiesPositionWithExperienceSlogan40')
                },
                {
                  id: 2,
                  content: this.$t('facilitiesPositionWithExperienceSlogan41')
                }
              ]
            }
          ]
        },
        {
          id: 5,
          category: this.$t('logisticsPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('logisticsPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan6')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan7')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('logisticsPositionWithExperienceSlogan8'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan9')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan10')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan13')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('logisticsPositionWithExperienceSlogan14'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan15')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan16')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan17')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan18')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('logisticsPositionWithExperienceSlogan19'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan20')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan21')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan22')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('logisticsPositionWithExperienceSlogan23')
                },
                {
                  id: 1,
                  content: this.$t('logisticsPositionWithExperienceSlogan24')
                },
                {
                  id: 2,
                  content: this.$t('logisticsPositionWithExperienceSlogan25')
                }
              ]
            }
          ]
        },
        {
          id: 6,
          category: this.$t('hospitalityPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan5')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan6'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan7')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan8')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan9')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan10'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan11')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan12')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan13')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan14')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan15')
                }
              ]
            },
            {
              id: 3,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan16'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan17')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan18')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan19')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan20')
                }
              ]
            },
            {
              id: 4,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan21'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan22')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan23')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan24')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan25')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan26')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan27')
                }
              ]
            },
            {
              id: 5,
              itemTitle: this.$t('hospitalityPositionWithExperienceSlogan28'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan29')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan30')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan31')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('hospitalityPositionWithExperienceSlogan32')
                },
                {
                  id: 1,
                  content: this.$t('hospitalityPositionWithExperienceSlogan33')
                },
                {
                  id: 2,
                  content: this.$t('hospitalityPositionWithExperienceSlogan34')
                }
              ]
            }
          ]
        },
        {
          id: 7,
          category: this.$t('miscellaneousPositionWithoutExperienceSlogan1'),
          itemList: [
            {
              id: 0,
              itemTitle: this.$t('miscellaneousPositionWithExperienceSlogan1'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan2')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan3')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan4')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan5')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan6')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan7')
                }
              ]
            },
            {
              id: 1,
              itemTitle: this.$t('miscellaneousPositionWithExperienceSlogan8'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan9')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan10')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan11')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan12')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan13')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan14')
                }
              ]
            },
            {
              id: 2,
              itemTitle: this.$t('miscellaneousPositionWithExperienceSlogan15'),
              itemResponsibilitiesList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan16')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan17')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan18')
                }
              ],
              itemJobsBriefList: [
                {
                  id: 0,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan19')
                },
                {
                  id: 1,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan20')
                },
                {
                  id: 2,
                  content: this.$t('miscellaneousPositionWithExperienceSlogan21')
                }
              ]
            }
          ]
        }
      ]
    }
  },
  components: {
    ChatUserStep,
    SocialSPaceUserStep,
    ResultStep,
    JobsCategoryItem
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.jobs')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      profile: 'auth/profile',
      step: 'user/step'
    })
  },
  created () {
    if (this.isAuthenticated) {
      this.changeStep(2)
    } else {
      this.changeStep(1)
    }
  },
  methods: {
    ...mapMutations({
      changeStep: 'user/changeStep'
    }),
    changeTab (tabName) {
      this.currentActiveTab = tabName
    }
  }
}
