import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageFile',
  props: {
    value: {
      type: String
    },
    image: {
      type: String
    },
    id: {
      type: String
    },
    refName: {
      type: String
    },
    isAvatar: {
      type: Boolean
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    position: {
      type: String,
      default: 'center'
    },
    imageId: {
      type: String,
      default: 'input-image'
    },
    maxSize: {
      type: Number,
      default: 3
    }
  },
  emits: ['input'],
  data () {
    return {
      fullImage: '',
      cropImg: '',
      isEditImageMode: false
    }
  },
  components: { VueCropper },
  watch: {
    image () {
      this.fullImage = this.image
      this.cropImg = this.image
    }
  },
  created () {
    if (this.image) {
      this.fullImage = this.image
      this.cropImg = this.image
    }
  },
  methods: {
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.isEditImageMode = false
      this.$emit('input', this.cropImg)
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file && file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      const fileSizeMB = file.size / 1024 / 1024
      if (fileSizeMB > this.maxSize) {
        this.$toasted.error(this.$t('errorFileSize').replace(':value', this.maxSize))
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.fullImage = event.target.result
          this.isEditImageMode = true
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom (percent) {
      this.$refs.cropper.relativeZoom(percent)
    }
  }
}
