import { mapMutations, mapGetters } from 'vuex'

const videoPopup = () => import('@/components/popups/video-popup/index.vue')
const JobRegister = () => import('@/components/popups/job-register/index.vue')
const RecoveryPopup = () => import('@/components/popups/recovery/index.vue')
const Registration = () => import('@/components/popups/registration/index.vue')
const LoginByQRCode = () => import('@/components/popups/QRLogin')

export default {
  name: 'hidden',
  components: {
    videoPopup,
    JobRegister,
    RecoveryPopup,
    Registration,
    LoginByQRCode
  },
  computed: {
    ...mapGetters({
      isShowVideoPopup: 'packages/isShowVideoPopup',
      isShowJobRegisterPopup: 'home/isShowJobRegisterPopup',
      isShowRecoveryPopup: 'auth/isShowRecoveryPopup',
      registrationPopup: 'auth/registrationPopup',
      qrCodeLoginPopup: 'auth/qrCodeLoginPopup'
    })
  },
  methods: {
    ...mapMutations({
      changeShowVideoPopup: 'packages/CHANGE_SHOW_VIDEO_POPUP',
      changeShowJobRegisterPopup: 'home/changeShowJobsPopup',
      changeShowRecoveryPopup: 'auth/changeShowRecoveryPopup',
      changeRegistrationPopup: 'auth/changeRegistrationPopup',
      changeLoginByQRPopup: 'auth/changeLoginByQRPopup'
    })
  }
}
