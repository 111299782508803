<template>
  <div class="not-found">
    <h3>{{$t('weCantFindPage')}}</h3>
    <img src="@/assets/img/404.png" alt="">
    <router-link :to="{name: 'home'}">{{$t('backToHome')}}</router-link>
  </div>
</template>

<script>
export default {
  name: 'not-found'
}
</script>

<style scoped lang="scss">
.not-found{
  height: 100vh;
  background: linear-gradient(180deg, rgb(0, 0, 0), rgb(24, 173, 100));
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respondTo(860px){
    padding: 0 20px;
  }
  @include respondTo(500px){
    height: auto;
    padding: 40px 20px 60px;
  }
  h3{
    margin-top: 80px;
    font-weight: normal;
    width: 800px;
    text-align: center;
    color: #ffffff;
    font-size: 46px;
    line-height: 1.2;
    @include respondTo(768px){
      font-size: 30px;
      width: 100%;
    }
    @include respondTo(485px){
      font-size: 18px;
      margin-top: 30px;
    }
  }
  img{
    margin-top: 80px;
    object-fit: contain;
    @include respondTo(860px){
      width: 100%;
    }
    @include respondTo(491px){
      font-size: 30px;
      width: 100%;
    }
  }
  a{
    text-decoration: none;
    margin-top: 60px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 25px;
    padding: 15px 30px;
    transition: background $trans, color $trans;
    &:hover {
      background: $white;
      color: $green;
    }
    @include respondTo(600px){
      padding: 10px 20px;
    }
  }
}
</style>
