import payment from './payment'
import user from './user'
import media from './media'
import location from './location'
import auth from './auth'

export default {
  payment,
  user,
  media,
  location,
  auth
}
