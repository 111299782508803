<template>
  <div>
    <div class="user-info">
      <form action=""
            class="user-info__form"
            @submit.prevent="onSubmit">

        <div class="form-row">
          <div class="form-col">
            <label :class="firstNameErrors.length !== 0? 'error': 'valid'">
              <span>{{ $t('firstName') }}</span>
              <input
                  type="text"
                  autocomplete="off"
                  v-model.trim="payload.firstName"
                  @blur="$v.payload.firstName.$touch()"
              >
              <span v-for="(item, index) in firstNameErrors" :key="index" class="input-errors">{{ item }}</span>
            </label>
          </div>
          <div class="form-col">
            <label :class="lastNameErrors.length !== 0? 'error': 'valid'">
              <span>{{ $t('lastName') }}</span>
              <input
                  type="text"
                  autocomplete="off"
                  v-model.trim="payload.lastName"
                  @blur="$v.payload.lastName.$touch()">
              <span v-for="(item, index) in lastNameErrors" :key="index" class="input-errors">{{ item }}</span>
            </label>
          </div>
        </div>

        <div class="form-row">
          <label :class="emailErrors.length !== 0? 'error': 'valid'">
            <span>{{ $t('email') }}</span>
            <input
                type="text"
                autocomplete="off"
                v-model.trim="payload.email"
                @blur="$v.payload.email.$touch()">
            <span v-for="(item, index) in emailErrors" :key="index" class="input-errors">{{ item }}</span>
            <span class="detail">
              {{ $t('emailMessage') }}
            </span>
          </label>
        </div>

        <div class="form-row">
          <label :class="nicknameErrors.length !== 0? 'error': 'valid'">
            <span>{{ $t('nickname') }}</span>
            <input
                type="text"
                autocomplete="off"
                @input="normalizeNickname"
                v-model.trim="payload.nickname"
                @blur="$v.payload.nickname.$touch()">
            <span v-for="(item, index) in nicknameErrors" :key="index" class="input-errors">{{ item }}</span>
          </label>
        </div>

        <div class="form-action">
          <BaseButton
              :disabled="this.$v.payload.$invalid || errorUniqNickname"
              :progress="loading || checkNicknameLoading">
            {{ $t('btnNext') }}
          </BaseButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/base-button/index.vue'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
const alphaOnly = (value) => ALPHAREGEX.test(value) || (NICKNAMEREGEX.test(value))
// eslint-disable-next-line
const NICKNAMEREGEX = /^[a-z0-9_\-\.]+$/
const ALPHAREGEX = /^[a-zA-Z0-9_]*$/

export default {
  name: 'UserInfoStep',
  mixins: [validationMixin],
  data () {
    return {
      payload: {
        firstName: '',
        lastName: '',
        email: '',
        nickname: ''
      },
      validationErrors: {},
      localLoading: false,
      checkNicknameLoading: false,
      errorUniqNickname: false
    }
  },
  components: {
    BaseButton
  },
  validations: {
    payload: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        email,
        required
      },
      nickname: {
        required,
        alphaOnly,
        uniq () {
          return !this.errorUniqNickname
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'auth/profile',
      loading: 'user/updateUserLoading'
    }),
    firstNameErrors () {
      const error = []
      if (!this.$v.payload.firstName.$dirty) {
        return error
      }
      if (!this.$v.payload.firstName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.firstName) {
        this.validationErrors.firstName.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    lastNameErrors () {
      const error = []
      if (!this.$v.payload.lastName.$dirty) {
        return error
      }
      if (!this.$v.payload.lastName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.lastName) {
        this.validationErrors.lastName.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    emailErrors () {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    nicknameErrors () {
      const error = []
      if (!this.$v.payload.nickname.$dirty) {
        return error
      }
      if (!this.$v.payload.nickname.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.nickname.uniq) {
        error.push(this.$t('nicknameExist'))
      }
      if (!this.$v.payload.nickname.alphaOnly) {
        error.push(this.$t('enterLatinLetter'))
      }
      if (this.validationErrors.nickname) {
        this.validationErrors.nickname.forEach((row) => {
          error.push(row)
        })
      }
      return error
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.setProfile()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateProfile: 'user/saveProfile',
      getProfile: 'auth/getMyProfile',
      checkUsername: 'user/checkHolaUsername'
    }),
    ...mapMutations({
      changeStep: 'user/changeStep',
      changeOffersStep: 'offers/changeStep'
    }),
    setProfile () {
      this.payload.firstName = this.profile.first_name
      this.payload.lastName = this.profile.last_name
      this.payload.email = this.profile.email
      this.payload.nickname = this.profile.nickname
    },
    normalizeNickname (e) {
      this.validationErrors.nickname = []
      this.payload.nickname = this.payload.nickname.toLowerCase()
      if (this.payload.nickname.length) {
        this.checkNicknameLoading = true
        this.changeValidationUserName()
      }
    },
    changeValidationUserName: debounce(function () {
      this.checkUsername(this.payload.nickname).then((response) => {
        this.errorUniqNickname = response.data.info !== 'Nickname is unique'
      }).finally(() => {
        this.checkNicknameLoading = false
        this.$v.payload.nickname.$touch()
      })
    }, 500),
    onSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.localLoading = true
        const sendData = {}
        sendData.first_name = this.payload.firstName
        sendData.last_name = this.payload.lastName
        sendData.email = this.payload.email
        sendData.nickname = this.payload.nickname
        this.updateProfile(sendData).then(() => {
          this.$toasted.success(this.$t('changesSaved'))
          this.getProfile().then(() => {
            this.changeStep(3)
            this.changeOffersStep(3)
            if (this.$route.name === 'offers') {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          })
        }).catch((error) => {
          const { data } = error.data
          if (data.field === 'nickname' && data.type === 'exists') {
            this.validationErrors = { nickname: [this.$t('nicknameExist')] }
          }
          if (data.field === 'email' && data.type === 'exists') {
            this.validationErrors = { email: [this.$t('emailExists')] }
          }
        }).finally(() => {
          this.localLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info{
  &__form{
    label{
      display: block;
      width: 100%;
      span{
        display: block;
        font-size: 14px;
        color: #161817;
        opacity: .7;
        margin-bottom: 5px;
        &.detail{
          font-size: 12px;
          line-height: 1.1;
          color: #858585;
          margin-top: 5px;
        }
        &.input-errors{
          color: $red;
          font-size: 12px;
          margin-top: 5px;
        }
      }
      &.error input{
        border-color: $red;
      }
    }
    input{
      font-family: $mont;
      font-size: 16px;
      color: rgba(0,0,0,1);
      max-height: 32px;
      flex: 1 1 auto;
      line-height: 20px;
      padding: 8px 0 8px;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      border-bottom: 1px solid rgba(0,0,0,.42);
      border-top: none;
      border-left: none;
      border-right: none;
      transition: border-color $trans;
      &:focus{
        border-color: $green;
      }
    }
  }
  .form-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 15px 0;
    @include respondTo(600px){
      flex-direction: column;
    }
  }
  .form-col{
    width: 50%;
    @include respondTo(600px){
      width: 100%;
      margin: 10px 0;
      &:first-child label{
        max-width: none;
      }
    }
    &:first-child label{
      max-width: 95%;
      @include respondTo(600px){
        max-width: none;
      }
    }
  }
  .form-action{
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    button {
      height: 40px;
      padding: 5px;
    }
  }
  .form-img{
    margin: 20px 0;
    width: 100%;
    &__title,
    &__message{
      display: block;
      font-size: 14px;
      color: #161817;
      opacity: .7;
      margin-bottom: 5px;
    }
  }
}
</style>
