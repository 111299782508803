<template>
  <div class="loader" :data-size="size" :data-color="color"></div>
</template>
<script>
export default {
  name: 'loader',
  props: {
    color: {
      type: String,
      default: 'green'
    },
    size: {
      type: String,
      default: 'normal'
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/animation";

.loader {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  border: 2px solid;
  border-radius: 50%;
  animation: spinner infinite .7s linear both;

  &[data-size='large'] {
    width: 50px;
    height: 50px;
  }

  &[data-size='normal'] {
    width: 30px;
    height: 30px;
    @include respondTo(580px) {
      width: 25px;
      height: 25px;
    }
  }

  &[data-size='small'] {
    width: 20px;
    height: 20px;
  }

  &[data-color='green'] {
    border-color: transparent transparent $white transparent;
  }

  &[data-color='white'] {
    border-color: transparent transparent $white transparent;
  }

  &[data-color='default'] {
    border-color: transparent transparent $green transparent !important;
  }
}
</style>
