import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_ROOT_AUTH}/auth`

export default {
  requestMagicRecovery (data) {
    return apiReq.post(`${BASE_URL}/user/restore-magic`, data)
  },
  authByCode (data) {
    return apiReq.post(`${BASE_URL}/user/qr-login?fingerprint=${data.fingerprint}`, data.data)
  },
  updatePhone (data) {
    return apiReq.post(`${BASE_URL}/phone/update-phone`, data)
  }
}
