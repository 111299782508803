import { VueSlideToggle } from 'vue-slide-toggle'

export default {
  name: 'accordion',
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  created () {
  },
  methods: {
    toggleCardState () {
      this.expanded = !this.expanded
    }
  }
}
