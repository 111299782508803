import axios from 'axios'

export default {
  async coordinatesByAddress (query) {
    const { data } = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: query,
        format: 'json',
        addressdetails: 1,
        limit: 1
      }
    })
    const coordinates = {
      latitude: 1,
      longitude: 1
    }
    if (data && data[0]) {
      coordinates.latitude = Number(data[0].lat)
      coordinates.longitude = Number(data[0].lon)
    }
    return coordinates
  }
}
