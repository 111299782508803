<template>
  <div class="result-step">
    <div class="result-title">
      {{ $t('congratulations') }}
    </div>
    <div class="result-action">
      <a :href="isIOSDevice ? 'https://apps.apple.com/us/app/sayhola/id1474217306?ls=1' : 'https://play.google.com/store/apps/details?id=com.xgroup.hola.hola'"
         target="_blank">
        {{ $t('downloadApp') }}
      </a>
      <a href="https://privateapp.io/en/faq"
         target="_blank">
        {{ $t('faqAndVideo') }}
      </a>
    </div>
    <div class="result-message">
      <div class="jobs-info">
        <div class="jobs-info__list">
          <div class="jobs-info__item">
            1. {{ $t('jobsInfo.slogan2') }}
          </div>
          <div class="jobs-info__item">
            2. {{ $t('jobsInfo.slogan4') }}
          </div>
          <div class="jobs-info__item">
            3. {{ $t('jobsInfo.slogan5') }}
          </div>
          <div class="jobs-info__item">
            4. <span v-html="$t('jobsInfo.slogan6')"></span>
            <img src="../../../assets/img/home/qr-code.png" alt="" class="qr-code">
          </div>
          <div class="jobs-info__item">
            5. {{ $t('jobsInfo.slogan7') }}
          </div>
          <div class="jobs-info__item">
            6. {{ $t('jobsInfo.slogan8') }}
          </div>
          <div class="jobs-info__item">
            7. {{ $t('jobsInfo.slogan9') }}
            <ol>
              <li>{{ $t('jobsInfo.slogan10') }}</li>
              <li>{{ $t('jobsInfo.slogan11') }}</li>
              <li>{{ $t('jobsInfo.slogan12') }}</li>
              <li>{{ $t('jobsInfo.slogan13') }}</li>
              <li>{{ $t('jobsInfo.slogan14') }}</li>
            </ol>
          </div>
          <div class="jobs-info__item">
            8. {{ $t('jobsInfo.slogan15') }}
          </div>
        </div>
        <div class="jobs-info__message">
          {{ $t('jobsInfo.slogan16') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isIOSDevice } from '@/helpers/variables/index.js'
export default {
  name: 'ResultStep',
  data () {
    return {
      payload: {}
    }
  },
  computed: {
    isIOSDevice
  }
}
</script>

<style lang="scss" scoped>
.result{
  &-title{
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
  }
  &-action{
    max-width: 250px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a{
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      min-width: 270px;
      min-height: 50px;
      border-radius: 8px;
      border: 1px solid transparent;
      background: #00b764;
      color: $white;
      text-transform: uppercase;
      transition: color $trans, background $trans, border-color $trans;
      @include respondTo(600px){
        min-width: 220px;
        min-height: 50px;
      }
      &:not(:first-child){
        margin-top: 15px;
      }
      &:hover{
        background: $white;
        color: #00b764;
        border: 1px solid #00b764;
      }
      &.mobile-link {
        display: none;
        @include respondTo(600px){
          display: flex;
        }
      }
    }
  }
}
</style>
