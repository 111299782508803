<template>
  <div>
    <div class="user-info">
      <div class="social-profiles" v-if="socialProfiles && socialProfiles.results.length">
        <div class="social-profiles__title">
          {{ $t('selectSocialProfile') }}:
        </div>
        <div class="social-profiles__list">
          <div class="social-profiles__item"
               v-for="(profile,index) in socialProfiles.results"
               :key="index">
            <div class="social-profiles__item-wrap"
                 :style="{ backgroundImage: `url(${profile.picture})`}"
                 @click="setActiveProfile(profile)">
              <div class="selected-box" v-if="activeProfile && activeProfile.nickname === profile.nickname"></div>
              <p>
                {{ profile.nickname }}
              </p>
            </div>
          </div>
        </div>
      </div>
        <p class="user-info__title" v-if="socialProfiles && socialProfiles.results.length">
          {{ $t('orCreateNewProfile') }}
        </p>
      <form action=""
            class="user-info__form"
            :class="{ 'disabled': !!activeProfile }"
            @submit.prevent="onSubmit">

        <div class="form-row">
          <div class="form-img">
            <div class="form-img__title">
              {{ $t('profilePicture') }}
            </div>
            <image-file
                :refName="'logoImage'"
                :id="'logo-image'"
                :image="payload.picture"
                v-model="payload.picture"
                :is-avatar="false">
            </image-file>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col">
            <label :class="firstNameErrors.length !== 0? 'error': 'valid'">
              <span>{{ $t('firstName') }}</span>
              <input
                  type="text"
                  autocomplete="off"
                  v-model.trim="payload.firstName"
                  @blur="$v.payload.firstName.$touch()"
              >
              <span v-for="(item, index) in firstNameErrors" :key="index" class="input-errors">{{ item }}</span>
            </label>
          </div>
          <div class="form-col">
            <label :class="lastNameErrors.length !== 0? 'error': 'valid'">
              <span>{{ $t('lastName') }}</span>
              <input
                  type="text"
                  autocomplete="off"
                  v-model.trim="payload.lastName"
                  @blur="$v.payload.lastName.$touch()">
              <span v-for="(item, index) in lastNameErrors" :key="index" class="input-errors">{{ item }}</span>
            </label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-col">
            <label :class="nicknameErrors.length !== 0? 'error': 'valid'">
            <span>{{ $t('nickname') }}</span>
            <input
                type="text"
                autocomplete="off"
                @input="changeInputUsername"
                v-model.trim="payload.nickname"
                @blur="$v.payload.nickname.$touch()">
            <span v-for="(item, index) in nicknameErrors" :key="index" class="input-errors">{{ item }}</span>
          </label>
          </div>
          <div class="form-col">
            <label :class="textLocationErrors.length !== 0? 'error': 'valid'">
            <span>{{ $t('location') }}</span>
            <input
                type="text"
                autocomplete="off"
                v-model.trim="payload.textLocation"
                @blur="$v.payload.textLocation.$touch()">
            <span v-for="(item, index) in textLocationErrors" :key="index" class="input-errors">{{ item }}</span>
          </label>
          </div>

        </div>

        <div class="form-action">
          <BaseButton
              :disabled="this.$v.$invalid || !this.payload.picture"
              :progress="createProfileLoading || checkNicknameLoading || submitting">
            {{ $t('btnFinish') }}
          </BaseButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ImageFile from '@/components/features/image-file/index.vue'
import BaseButton from '@/components/atoms/base-button/index.vue'
// import Loader from "@/components/atoms/Spinner.vue";
import { maxLength, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import api from '@/api'
const alphaOnly = (value) => ALPHAREGEX.test(value) || (NICKNAMEREGEX.test(value))
// eslint-disable-next-line
const NICKNAMEREGEX = /^[a-z0-9_\-\.]+$/
const ALPHAREGEX = /^[a-zA-Z0-9_]*$/
export default {
  name: 'UserLogoStep',
  mixins: [validationMixin],
  data () {
    return {
      payload: {
        firstName: '',
        lastName: '',
        nickname: '',
        picture: '',
        textLocation: ''
      },
      submitting: false,
      file: '',
      validationErrors: {},
      localLoading: false,
      errorUniqNickname: false,
      checkNicknameLoading: false,
      activeProfile: '',
      isLoadingCoords: false
    }
  },
  components: {
    BaseButton,
    ImageFile
    // Loader
  },
  validations: {
    payload: {
      firstName: {
        maxLength: maxLength(20)
      },
      lastName: {
        maxLength: maxLength(20)
      },
      textLocation: {
        required,
        maxLength: maxLength(50)
      },
      nickname: {
        required,
        alphaOnly,
        maxLength: maxLength(20),
        uniq () {
          return !this.errorUniqNickname
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'auth/profile',
      createProfileLoading: 'user/createProfileLoading',
      socialProfiles: 'user/socialProfiles',
      offerData: 'auth/offerData'
    }),
    firstNameErrors () {
      const error = []
      if (!this.$v.payload.firstName.$dirty) {
        return error
      }
      if (!this.$v.payload.firstName.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 20))
      }
      if (this.validationErrors.firstName) {
        this.validationErrors.firstName.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    lastNameErrors () {
      const error = []
      if (!this.$v.payload.lastName.$dirty) {
        return error
      }
      if (!this.$v.payload.lastName.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 20))
      }
      if (this.validationErrors.lastName) {
        this.validationErrors.lastName.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    textLocationErrors () {
      const error = []
      if (!this.$v.payload.textLocation.$dirty) {
        return error
      }
      if (!this.$v.payload.textLocation.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.textLocation.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 50))
      }
      if (this.validationErrors.textLocation) {
        this.validationErrors.textLocation.forEach((row) => {
          error.push(row)
        })
      }
      return error
    },
    nicknameErrors () {
      const error = []
      if (!this.$v.payload.nickname.$dirty) {
        return error
      }
      if (!this.$v.payload.nickname.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.nickname.maxLength) {
        error.push(this.$t('validationMaxLengthField').replace(':count', 20))
      }
      if (!this.$v.payload.nickname.alphaOnly) {
        error.push(this.$t('enterLatinLetter'))
      }
      if (!this.$v.payload.nickname.uniq) {
        error.push(this.$t('errorNicknameExists'))
      }
      if (this.validationErrors.nickname) {
        this.validationErrors.nickname.forEach((row) => {
          error.push(row)
        })
      }
      return error
    }
  },
  created () {
    if (this.profile) {
      this.setProfile()
    }
    this.getSocialSpaceProfiles()
  },
  methods: {
    ...mapActions({
      updateProfile: 'user/saveProfile',
      getProfile: 'auth/getMyProfile',
      createSocialProfile: 'user/createProfile',
      checkUsername: 'user/checkUsername',
      getSocialSpaceProfiles: 'user/getSocialSpaceProfiles',
      buyPackage: 'packages/buy'
    }),
    ...mapMutations({
      changeStep: 'user/changeStep',
      changeOffersStep: 'offers/changeStep'
    }),
    setActiveProfile (params) {
      if (params.nickname === this.activeProfile?.nickname) {
        this.activeProfile = ''
        Object.assign(this.payload, {
          picture: '',
          firstName: '',
          lastName: '',
          nickname: '',
          textLocation: '',
          category: 'Individual',
          isActive: true,
          isPublic: true,
          showFollowers: false,
          isChatActive: false
        })
      } else {
        this.activeProfile = params
        Object.assign(this.payload, params)
      }
    },
    setProfile () {
      this.payload.firstName = this.profile.first_name
      this.payload.lastName = this.profile.last_name
      this.payload.nickname = this.profile.nickname
    },
    normalizeNickname (e) {
      this.payload.nickname = e.target.value.toLowerCase()
    },
    changeInputUsername (e) {
      this.validationErrors.nickname = []
      this.payload.nickname = e.target.value.toLowerCase()
      if (this.payload.nickname.length && this.payload.nickname.length < 20) {
        this.checkNicknameLoading = true
        this.changeValidationUserName()
      }
    },
    changeValidationUserName: debounce(function () {
      this.checkUsername(this.payload.nickname).then((response) => {
        this.errorUniqNickname = response.info !== 'Nickname is unique'
      }).finally(() => {
        this.checkNicknameLoading = false
        this.$v.payload.nickname.$touch()
      })
    }, 500),
    smoothScroll () {
      setTimeout(() => {
        const element = document.getElementById('jobs')
        if (element) {
          element.scrollTop = 0
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }, 200)
    },
    async setPackage () {
      this.submitting = true
      try {
        const obj = {
          package_id: this.offerData.package_id,
          special_offer_id: this.offerData.id
        }
        await this.buyPackage(obj).then(() => {
          this.smoothScroll()
        })
      } catch (error) {
        this.submitting = false
        if (error.response.data.data.special_offer_id) {
          this.$toasted.error(this.$t('maximumOffersCountUse'))
        }
      }
    },
    async onSubmit () {
      if (!this.activeProfile) {
        this.$v.$touch()
        if (!this.payload.picture) {
          this.$toasted.error(this.$t('emptyProfilePictureField'))
          return
        }
        if (!this.$v.$invalid) {
          this.localLoading = true
          const data = {
            first_name: this.payload.firstName,
            last_name: this.payload.lastName,
            nickname: this.payload.nickname,
            category: 'Individual',
            text_location: this.payload.textLocation
          }
          if (this.payload.picture) {
            data.picture = this.payload.picture
          }
          const location = await api.location.coordinatesByAddress(this.payload.textLocation)
          data.location = JSON.stringify(location)

          try {
            await this.createSocialProfile(data).then(() => {
              this.$toasted.success(this.$t('successCreateProfile'))
              if (this.$route.name !== 'offers') {
                this.changeStep(4)
                this.smoothScroll()
              } else {
                if (parseFloat(this.offerData.price) > 0) {
                  this.changeOffersStep(4)
                  this.smoothScroll()
                } else {
                  this.setPackage()
                }
              }
            })
          } catch (e) {
            const { nickname } = e.data
            if (nickname) {
              this.validationErrors = { nickname: [this.$t('nicknameExist')] }
            }
          } finally {
            this.localLoading = false
          }
        }
      } else {
        if (this.$route.name !== 'offers') {
          this.changeStep(4)
          this.smoothScroll()
        } else {
          if (parseFloat(this.offerData.price) > 0) {
            this.changeOffersStep(4)
            this.smoothScroll()
          } else {
            this.setPackage()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info{
  &__form{
    &.disabled{
      .form-row{
        opacity: .6;
        pointer-events: none;
        user-select: none;
      }
    }
    label{
      display: block;
      width: 100%;
      span{
        display: block;
        font-size: 14px;
        color: #161817;
        opacity: .7;
        margin-bottom: 5px;
        &.detail{
          font-size: 12px;
          line-height: 1.1;
          color: #858585;
          margin-top: 5px;
        }
        &.input-errors{
          color: $red;
          font-size: 12px;
          margin-top: 5px;
        }
      }
      &.error input{
        border-color: $red;
      }
    }
    input{
      font-family: $mont;
      font-size: 16px;
      color: rgba(0,0,0,1);
      max-height: 32px;
      flex: 1 1 auto;
      line-height: 20px;
      padding: 8px 0 8px;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      border-bottom: 1px solid rgba(0,0,0,.42);
      border-top: none;
      border-left: none;
      border-right: none;
      transition: border-color $trans;
      &:focus{
        border-color: $green;
      }
    }
  }
  .form-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 15px 0;
    @include respondTo(600px){
      flex-direction: column;
    }
  }
  .form-col{
    width: 50%;
    &:first-child label{
      max-width: 95%;
    }
    @include respondTo(600px){
      width: 100%;
      margin: 10px 0;
      &:first-child label{
        max-width: none;
      }
    }
  }
  .form-action{
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    button {
      height: 40px;
      padding: 5px;
    }
  }
  .form-img{
    margin: 20px 0;
    width: 100%;
    &__title,
    &__message{
      display: block;
      font-size: 14px;
      color: #161817;
      opacity: .7;
      margin-bottom: 5px;
    }
  }
  &__title{
    color: rgb(153, 153, 153);
    font-weight: 400;
    font-size: 16px;
    margin: 30px 0 10px;
    text-align: center;
  }
}
.social-profiles{
  &__title{
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 15px;
    @include respondTo(600px){
      font-size: 16px;
    }
  }
  &__list{
    margin-right: -25px;
    margin-left: -25px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: -12px;
  }
  &__item{
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    cursor: pointer;
    padding: 12px;
    @include respondTo(800px){
      flex: 0 0 20%;
      max-width: 20%;
    }
    @include respondTo(550px){
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-wrap{
      height: 100px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      overflow: hidden;
    }
    p{
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px;
      color: $white;
      text-align: center;
      @include truncate()
    }
  }
  .selected-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 121, 23, 0.7);
    &:after{
      content: '\2714';
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 40px;
    }
  }
}
</style>
