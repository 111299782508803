<template>
  <button
      :class="[
      'form--button',
      {
        'button--loading': loading,
        'button--disabled': disabled,
        'button--oval': oval,
        'button--secondary': secondary,
        'button--red': red,
        'button--dark': dark
      }
    ]"
      v-bind="$attrs"
      type="submit"
      v-on="$listeners"
  >
    <span>
      <slot/>
    </span>
    <slot name="icon"/>
  </button>
</template>

<script>
export default {
  name: 'base-button',
  props: {
    loading: Boolean,
    disabled: Boolean,
    oval: Boolean,
    secondary: Boolean,
    red: Boolean,
    dark: Boolean
  }
}
</script>

<style lang="scss">
.form--button {
  font-family: 'Montserrat', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 30px auto 0;
  width: 195px;
  height: 46px;
  background: $main-bg-colored;
  font-size: 17px;
  font-weight: 500;
  color: $text-lightest;
  border-radius: 8px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: rgba($main-bg-colored, 0.7);
  }

  &.button--disabled {
    background: rgba($main-bg-colored, 0.7);
    cursor: default;

    &:hover {
      background: rgba($main-bg-colored, 0.7);
    }
  }

  &.button--loading {
    position: relative;
    background: $main-bg-colored;
    cursor: default;

    &:hover {
      background: $main-bg-colored;
    }

    > span {
      display: none;
    }

    @keyframes rotate-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: 9px;
      left: calc(50% - 10px);
      border-bottom-color: $main-bg-light;
      border-right-color: $main-bg-light;
      border-top-color: rgba(0, 0, 0, 0);
      border-left-color: rgba(0, 0, 0, 0);
      animation: rotate-spinner .5s linear 0s infinite;
    }
  }

  &.button--oval {
    border-radius: 16px;
    text-transform: uppercase;
    height: 30px;
    font-size: 14px;
    padding: 0 18px;
    margin: 0;
    width: 115px;

    &.button--loading:before {
      content: "";
      display: block;
      position: absolute;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      border-bottom-color: $main-bg-light;
      border-right-color: $main-bg-light;
      border-top-color: rgba(0, 0, 0, 0);
      border-left-color: rgba(0, 0, 0, 0);
      animation: rotate-spinner .5s linear 0s infinite;
    }
  }

  &.button--secondary {
    background: transparent;
    color: $text-colored;

    &:hover {
      color: rgba($text-colored, 0.7);
    }
  }

  &.button-sm {
    width: 150px;
  }

  &.button-lg {
    width: auto;
    padding: 0 20px;
  }

  &.button--red {
    background: $text-error;
  }

  &.button--dark {
    background: $main-bg-darkest;

    &:hover {
      background: rgba($main-bg-darkest, 0.7);
    }

    &.button--disabled:hover {
      background: $main-bg-darkest;
    }
  }
}
</style>
