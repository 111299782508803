import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'privacy-policy',
  data () {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.privacyPolicy')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      privacyPolicy: 'policy/privacyPolicy'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ]),
    currentLang () {
      return this.currentLanguage.requestPrefix
    }
  },
  created () {
    this.getPolicy()
  },
  methods: {
    ...mapActions({
      getPrivacyPolicy: 'policy/getPrivacy'
    }),
    getPolicy () {
      this.getPrivacyPolicy(this.currentLang)
    }
  }
}
