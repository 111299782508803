<template>
  <div class="accordion" v-if="item">
    <div class="accordion-head" @click="toggleCardState" :class="{ 'active': expanded}">
      <div class="accordion-title">
        {{ item.category }}
      </div>
      <div class="accordion-icon">
        <div class="pluss">
          <div class="toggle" :class="{'open': !expanded}"></div>
        </div>
      </div>
    </div>

    <VueSlideToggle
        :open="expanded"
        tag="div"
        :duration="450"
        v-if="item.itemList">
      <div class="accordion-content">
        <ul class="accordion-list">
          <li class="accordion-item"
              v-for="(item) in item.itemList"
              :key="item.id">
            <JobsItem :item="item" />
          </li>
        </ul>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle'
import JobsItem from './JobsItem.vue'

export default {
  name: 'JobsCategoryItem',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle,
    JobsItem
  },
  methods: {
    toggleCardState () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &-title{
    font-size: 18px;
    @include respondTo(600px){
      font-size: 16px;
    }
  }
  &-head {
    background: transparent;
    border: 1px solid #00b764;
    color: $white;
    cursor: pointer;
    position: relative;
    display: block;
    padding: 14px 15px;
    transition: background-color $trans, color $trans, border-color $trans;
    @include respondTo(600px) {
      padding: 10px;
    }
    &.active,&:hover{
      border: 1px solid #00b764;
      background: #00b764;
      color: $white;
    }
  }

  &-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    $w: 12px;
    $h: 1px;
    $bg: #082C23;
    $transition: 0.25s all cubic-bezier(0.17, 0.67, 0.09, 0.97);

    .wrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .toggle {
      width: $w;
      height: $h;
      background: $bg;
      position: relative;
      transition: $transition;

      &.open::after {
        transform: rotate(90deg);
        opacity: 1;
      }

      &.open {
        transform: rotate(90deg);
      }

      &::after {
        content: "";
        width: $w;
        transition: $transition;
        transition-delay: 0.1s;
        height: $h;
        position: absolute;
        opacity: 0;
        background: $bg;
      }
    }
  }

  &-content {
    font-size: 14px;
    line-height: 20px;
    padding: 20px 15px;
    background-color: #fafafa;
    border: 1px solid #EEF0F3;

    p {
      font-size: 14px;
      line-height: 20px;
    }

    a {
      color: $black;
    }

    @include respondTo(600px) {
      padding: 10px 15px;
    }
  }

  &-title {
    font-size: 18px;
    line-height: 20px;
    max-width: 95%;
    text-transform: uppercase;
    @include respondTo(600px) {
      font-size: 14px;
      max-width: 92%;
    }
  }

  &-item {
    font-size: 16px;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
  &-icon{
    background: $white;
    .toggle{
      background: $white;
      &:after{
        background: $white;
      }
      .open:after{
        background: $white;
      }
    }
  }
  &-content{
    h4{
      font-size: 22px;
      line-height: 1.2;
      margin-bottom: 15px;
      @include respondTo(800px){
        font-size: 18px;
        line-height: 1.1;
      }
    }
    p{
      font-size: 16px;
      line-height: 1.3;
      margin: 15px 0;
      @include respondTo(800px){
        font-size: 14px;
        margin: 10px 0;
      }
    }
    ul {
      margin-bottom: 20px;
      li {
        font-size: 16px;
        line-height: 1.3;
        margin: 10px 0;
        @include respondTo(800px){
          line-height: 1.2;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
