import Vue from 'vue'
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import '../../../../assets/scss/swiper-bundle.scss'
import '../../../../assets/scss/effect-fade.scss'

SwiperClass.use([Pagination, Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'rewards',
  data () {
    return {
      currentSlide: 1,
      isLastSlide: false,
      swiperOptions: {
        initialSlide: 1,
        centeredSlides: true,
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          480: {
            slidesPerView: 2
          },
          640: {
            slidesPerView: 3
          }
        },
        pagination: {
          el: '.reward-pagination',
          clickable: true,
          bulletClass: 'reward-pagination__item',
          bulletActiveClass: 'active'
        }
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.rewards')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    changeSlide (index) {
      if (index.activeIndex + 1 === index.slides.length) {
        this.isLastSlide = true
      } else {
        this.isLastSlide = false
      }
    }
  }
}
