import apiReq from './request'

const HOLA_USER_API = process.env.VUE_APP_ROOT_AUTH

export default {
  getPrivacyPolicy (lang) {
    return apiReq.get(`${HOLA_USER_API}/user/info/privacy-policy?lang=${lang}`)
  },
  getTerms (lang) {
    return apiReq.get(`${HOLA_USER_API}/user/info/general-terms-use?lang=${lang}`)
  }
}
