import loader from '@/components/atoms/Loader.vue'

export default {
  name: 'base-button',
  data () {
    return {}
  },
  components: {
    loader
  },
  props: {
    label: {
      type: String,
      default: 'Submit'
    },
    progress: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    color: {
      type: String,
      default: 'yellow'
    }
  },
  methods: {
    changeEvent () {
      this.$emit('change-event')
    }
  }
}
