import { snakeCase } from 'lodash'
import store from '@/store'

export function base64ToFile (dataURI, filename) {
  if (!dataURI) return ''
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], `${filename}${mimeString.replace('image/', '.')}`, { type: mimeString })
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export function toCamelCaseRequestPayload (data) {
  const payload = {}
  Object.entries(data).forEach(e => {
    const [key, value] = e
    payload[snakeCase(key)] = value
  })
  return payload
}

export const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next()
    return
  }
  if (to.params.username) {
    next(`/${to.params.username}`)
  } else {
    next('/')
  }
}

export const isIOSDevice = () => {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

export const chatNamesForBridge = {
  telegram: 'tg_bridge_bot',
  whatsapp: 'wa_bridge_bot'
}

export const dataForCreateBridgeTGRoom = {
  initial_state: [{
    type: 'm.room.guest_access',
    state_key: '',
    content: {
      guest_access: 'can_join'
    }
  }],
  name: chatNamesForBridge.telegram,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_TELEGRAM_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
}

export const dataForCreateBridgeWARoom = {
  initial_state: [{
    type: 'm.room.guest_access',
    state_key: '',
    content: {
      guest_access: 'can_join'
    }
  }],
  name: chatNamesForBridge.whatsapp,
  type: 'm.room.guest_access',
  invite: [process.env.VUE_APP_WHATSAPP_BRIDGE_BOT],
  is_direct: true,
  preset: 'trusted_private_chat',
  visibility: 'private'
}
