import accordion from '@/components/atoms/accordion/index.vue'

export default {
  name: 'faq',
  data () {
    return {
      faqGroupFrequendly: [
        {
          id: 0,
          question: this.$t('faqGroupFrequendly.question1'),
          answer: this.$t('faqGroupFrequendly.answer1')
        },
        {
          id: 1,
          question: this.$t('faqGroupFrequendly.question2'),
          answer: this.$t('faqGroupFrequendly.answer2')
        },
        {
          id: 2,
          question: this.$t('faqGroupFrequendly.question3'),
          answer: this.$t('faqGroupFrequendly.answer3')
        },
        {
          id: 3,
          question: this.$t('faqGroupFrequendly.question4'),
          answer: this.$t('faqGroupFrequendly.answer4')
        },
        {
          id: 4,
          question: this.$t('faqGroupFrequendly.question5'),
          answer: this.$t('faqGroupFrequendly.answer5')
        },
        {
          id: 5,
          question: this.$t('faqGroupFrequendly.question6'),
          answer: this.$t('faqGroupFrequendly.answer6')
        }
      ],
      faqListGroupBlogging: [
        {
          id: 6,
          question: this.$t('faqListGroupBlogging.question1'),
          answer: this.$t('faqListGroupBlogging.answer1')
        },
        {
          id: 7,
          question: this.$t('faqListGroupBlogging.question2'),
          answer: this.$t('faqListGroupBlogging.answer2')
        },
        {
          id: 8,
          question: this.$t('faqListGroupBlogging.question3'),
          answer: this.$t('faqListGroupBlogging.answer3')
        },
        {
          id: 9,
          question: this.$t('faqListGroupBlogging.question4'),
          answer: this.$t('faqListGroupBlogging.answer4')
        },
        {
          id: 10,
          question: this.$t('faqListGroupBlogging.question5'),
          answer: this.$t('faqListGroupBlogging.answer5')
        },
        {
          id: 11,
          question: this.$t('faqListGroupBlogging.question6'),
          answer: this.$t('faqListGroupBlogging.answer6')
        },
        {
          id: 12,
          question: this.$t('faqListGroupBlogging.question7'),
          answer: this.$t('faqListGroupBlogging.answer7')
        },
        {
          id: 13,
          question: this.$t('faqListGroupBlogging.question8'),
          answer: this.$t('faqListGroupBlogging.answer8')
        },
        {
          id: 14,
          question: this.$t('faqListGroupBlogging.question9'),
          answer: this.$t('faqListGroupBlogging.answer9')
        },
        {
          id: 15,
          question: this.$t('faqListGroupBlogging.question10'),
          answer: this.$t('faqListGroupBlogging.answer10')
        },
        {
          id: 16,
          question: this.$t('faqListGroupBlogging.question11'),
          answer: this.$t('faqListGroupBlogging.answer11')
        },
        {
          id: 17,
          question: this.$t('faqListGroupBlogging.question12'),
          answer: this.$t('faqListGroupBlogging.answer12')
        }
      ],
      faqListGroupOpportunity: [
        {
          id: 18,
          question: this.$t('faqListGroupOpportunity.question1'),
          answer: this.$t('faqListGroupOpportunity.answer1')
        },
        {
          id: 19,
          question: this.$t('faqListGroupOpportunity.question2'),
          answer: this.$t('faqListGroupOpportunity.answer2')
        },
        {
          id: 20,
          question: this.$t('faqListGroupOpportunity.question3'),
          answer: this.$t('faqListGroupOpportunity.answer3')
        },
        {
          id: 21,
          question: this.$t('faqListGroupOpportunity.question4'),
          answer: this.$t('faqListGroupOpportunity.answer4')
        },
        {
          id: 22,
          question: this.$t('faqListGroupOpportunity.question5'),
          answer: this.$t('faqListGroupOpportunity.answer5')
        },
        {
          id: 23,
          question: this.$t('faqListGroupOpportunity.question6'),
          answer: this.$t('faqListGroupOpportunity.answer6')
        },
        {
          id: 24,
          question: this.$t('faqListGroupOpportunity.question7'),
          answer: this.$t('faqListGroupOpportunity.answer7')
        },
        {
          id: 25,
          question: this.$t('faqListGroupOpportunity.question8'),
          answer: this.$t('faqListGroupOpportunity.answer8')
        },
        {
          id: 26,
          question: this.$t('faqListGroupOpportunity.question9'),
          answer: this.$t('faqListGroupOpportunity.answer9')
        },
        {
          id: 27,
          question: this.$t('faqListGroupOpportunity.question10'),
          answer: this.$t('faqListGroupOpportunity.answer10')
        },
        {
          id: 28,
          question: this.$t('faqListGroupOpportunity.question11'),
          answer: this.$t('faqListGroupOpportunity.answer11')
        },
        {
          id: 29,
          question: this.$t('faqListGroupOpportunity.question12'),
          answer: this.$t('faqListGroupOpportunity.answer12')
        },
        {
          id: 30,
          question: this.$t('faqListGroupOpportunity.question13'),
          answer: this.$t('faqListGroupOpportunity.answer13')
        }
      ],
      faqListGroupSecurity: [
        {
          id: 31,
          question: this.$t('faqListGroupSecurity.question1'),
          answer: this.$t('faqListGroupSecurity.answer1')
        },
        {
          id: 32,
          question: this.$t('faqListGroupSecurity.question2'),
          answer: this.$t('faqListGroupSecurity.answer2')
        },
        {
          id: 33,
          question: this.$t('faqListGroupSecurity.question3'),
          answer: this.$t('faqListGroupSecurity.answer3')
        },
        {
          id: 34,
          question: this.$t('faqListGroupSecurity.question14'),
          answer: this.$t('faqListGroupSecurity.answer14')
        },
        {
          id: 35,
          question: this.$t('faqListGroupSecurity.question4'),
          answer: this.$t('faqListGroupSecurity.answer4')
        },
        {
          id: 36,
          question: this.$t('faqListGroupSecurity.question5'),
          answer: this.$t('faqListGroupSecurity.answer5')
        },
        {
          id: 37,
          question: this.$t('faqListGroupSecurity.question6'),
          answer: this.$t('faqListGroupSecurity.answer6')
        },
        {
          id: 38,
          question: this.$t('faqListGroupSecurity.question7'),
          answer: this.$t('faqListGroupSecurity.answer7')
        },
        {
          id: 39,
          question: this.$t('faqListGroupSecurity.question8'),
          answer: this.$t('faqListGroupSecurity.answer8')
        },
        {
          id: 40,
          question: this.$t('faqListGroupSecurity.question9'),
          answer: this.$t('faqListGroupSecurity.answer9')
        },
        {
          id: 41,
          question: this.$t('faqListGroupSecurity.question10'),
          answer: this.$t('faqListGroupSecurity.answer10')
        },
        {
          id: 42,
          question: this.$t('faqListGroupSecurity.question11'),
          answer: this.$t('faqListGroupSecurity.answer11')
        },
        {
          id: 43,
          question: this.$t('faqListGroupSecurity.question12'),
          answer: this.$t('faqListGroupSecurity.answer12')
        },
        {
          id: 44,
          question: this.$t('faqListGroupSecurity.question13'),
          answer: this.$t('faqListGroupSecurity.answer13')
        }
      ],
      faqListGroupTogether: [
        {
          id: 45,
          question: this.$t('faqListGroupTogether.question1'),
          answer: this.$t('faqListGroupTogether.answer1')
        },
        {
          id: 46,
          question: this.$t('faqListGroupTogether.question2'),
          answer: this.$t('faqListGroupTogether.answer2')
        },
        {
          id: 47,
          question: this.$t('faqListGroupTogether.question3'),
          answer: this.$t('faqListGroupTogether.answer3')
        },
        {
          id: 48,
          question: this.$t('faqListGroupTogether.question4'),
          answer: this.$t('faqListGroupTogether.answer4')
        },
        {
          id: 49,
          question: this.$t('faqListGroupTogether.question5'),
          answer: this.$t('faqListGroupTogether.answer5')
        },
        {
          id: 50,
          question: this.$t('faqListGroupTogether.question6'),
          answer: this.$t('faqListGroupTogether.answer6')
        }
      ],
      faqListGroupPremium: [
        {
          id: 51,
          question: this.$t('faqListGroupPremium.question1'),
          answer: this.$t('faqListGroupPremium.answer1')
        },
        {
          id: 52,
          question: this.$t('faqListGroupPremium.question2'),
          answer: this.$t('faqListGroupPremium.answer2')
        },
        {
          id: 53,
          question: this.$t('faqListGroupPremium.question3'),
          answer: this.$t('faqListGroupPremium.answer3')
        },
        {
          id: 54,
          question: this.$t('faqListGroupPremium.question4'),
          answer: this.$t('faqListGroupPremium.answer4')
        },
        {
          id: 55,
          question: this.$t('faqListGroupPremium.question5'),
          answer: this.$t('faqListGroupPremium.answer5')
        },
        {
          id: 56,
          question: this.$t('faqListGroupPremium.question6'),
          answer: this.$t('faqListGroupPremium.answer6')
        }
      ],
      faqListGroupSocial: [
        {
          id: 57,
          question: this.$t('faqListGroupSocial.question1'),
          answer: this.$t('faqListGroupSocial.answer1')
        },
        {
          id: 58,
          question: this.$t('faqListGroupSocial.question2'),
          answer: this.$t('faqListGroupSocial.answer2')
        },
        {
          id: 59,
          question: this.$t('faqListGroupSocial.question3'),
          answer: this.$t('faqListGroupSocial.answer3')
        },
        {
          id: 60,
          question: this.$t('faqListGroupSocial.question4'),
          answer: this.$t('faqListGroupSocial.answer4')
        },
        {
          id: 61,
          question: this.$t('faqListGroupSocial.question5'),
          answer: this.$t('faqListGroupSocial.answer5')
        },
        {
          id: 62,
          question: this.$t('faqListGroupSocial.question6'),
          answer: this.$t('faqListGroupSocial.answer6')
        },
        {
          id: 63,
          question: this.$t('faqListGroupSocial.question7'),
          answer: this.$t('faqListGroupSocial.answer7')
        },
        {
          id: 64,
          question: this.$t('faqListGroupSocial.question8'),
          answer: this.$t('faqListGroupSocial.answer8')
        },
        {
          id: 65,
          question: this.$t('faqListGroupSocial.question9'),
          answer: this.$t('faqListGroupSocial.answer9')
        },
        {
          id: 66,
          question: this.$t('faqListGroupSocial.question10'),
          answer: this.$t('faqListGroupSocial.answer10')
        },
        {
          id: 67,
          question: this.$t('faqListGroupSocial.question11'),
          answer: this.$t('faqListGroupSocial.answer11')
        },
        {
          id: 68,
          question: this.$t('faqListGroupSocial.question12'),
          answer: this.$t('faqListGroupSocial.answer12')
        }
      ],
      faqListGroupBridge: [
        {
          id: 69,
          question: this.$t('faqListGroupBridge.question1'),
          answer: this.$t('faqListGroupBridge.answer1')
        },
        {
          id: 70,
          question: this.$t('faqListGroupBridge.question2'),
          answer: this.$t('faqListGroupBridge.answer2')
        },
        {
          id: 71,
          question: this.$t('faqListGroupBridge.question3'),
          answer: this.$t('faqListGroupBridge.answer3')
        },
        {
          id: 72,
          question: this.$t('faqListGroupBridge.question4'),
          answer: this.$t('faqListGroupBridge.answer4')
        },
        {
          id: 73,
          question: this.$t('faqListGroupBridge.question5'),
          answer: this.$t('faqListGroupBridge.answer5')
        },
        {
          id: 74,
          question: this.$t('faqListGroupBridge.question6'),
          answer: this.$t('faqListGroupBridge.answer6')
        },
        {
          id: 75,
          question: this.$t('faqListGroupBridge.question7'),
          answer: this.$t('faqListGroupBridge.answer7')
        },
        {
          id: 76,
          question: this.$t('faqListGroupBridge.question8'),
          answer: this.$t('faqListGroupBridge.answer8')
        },
        {
          id: 77,
          question: this.$t('faqListGroupBridge.question9'),
          answer: this.$t('faqListGroupBridge.answer9')
        },
        {
          id: 78,
          question: this.$t('faqListGroupBridge.question10'),
          answer: this.$t('faqListGroupBridge.answer10')
        }
      ],
      faqListGroupDespaxo: [
        {
          id: 79,
          question: this.$t('despaxoFaqList.item1.question'),
          answer: this.$t('despaxoFaqList.item1.answer')
        },
        {
          id: 80,
          question: this.$t('despaxoFaqList.item2.question'),
          answer: this.$t('despaxoFaqList.item2.answer')
        },
        {
          id: 81,
          question: this.$t('despaxoFaqList.item3.question'),
          answer: this.$t('despaxoFaqList.item3.answer')
        },
        {
          id: 82,
          question: this.$t('despaxoFaqList.item4.question'),
          answer: this.$t('despaxoFaqList.item4.answer')
        },
        {
          id: 83,
          question: this.$t('despaxoFaqList.item5.question'),
          answer: this.$t('despaxoFaqList.item5.answer')
        },
        {
          id: 84,
          question: this.$t('despaxoFaqList.item6.question'),
          answer: this.$t('despaxoFaqList.item6.answer')
        },
        {
          id: 85,
          question: this.$t('despaxoFaqList.item7.question'),
          answer: this.$t('despaxoFaqList.item7.answer')
        },
        {
          id: 86,
          question: this.$t('despaxoFaqList.item8.question'),
          answer: this.$t('despaxoFaqList.item8.answer')
        },
        {
          id: 87,
          question: this.$t('despaxoFaqList.item9.question'),
          answer: this.$t('despaxoFaqList.item9.answer')
        },
        {
          id: 88,
          question: this.$t('despaxoFaqList.item10.question'),
          answer: this.$t('despaxoFaqList.item10.answer')
        },
        {
          id: 89,
          question: this.$t('despaxoFaqList.item11.question'),
          answer: this.$t('despaxoFaqList.item11.answer')
        },
        {
          id: 90,
          question: this.$t('despaxoFaqList.item12.question'),
          answer: this.$t('despaxoFaqList.item12.answer')
        },
        {
          id: 91,
          question: this.$t('despaxoFaqList.item13.question'),
          answer: this.$t('despaxoFaqList.item13.answer')
        },
        {
          id: 92,
          question: this.$t('despaxoFaqList.item14.question'),
          answer: this.$t('despaxoFaqList.item14.answer')
        },
        {
          id: 93,
          question: this.$t('despaxoFaqList.item15.question'),
          answer: this.$t('despaxoFaqList.item15.answer')
        }
      ]
    }
  },
  components: {
    accordion
  },
  mounted () {
    if (this.$route.hash) {
      document.getElementById(this.$route.hash.slice(1)).scrollIntoView()
    }
  }
}
