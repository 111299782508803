export default {
  name: 'advertise-with-hola',
  data () {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('programsAdvertiseHola')
      }
    }
  }
}
