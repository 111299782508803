import { youtubeIco } from '@/helpers/svg-icons'

export default {
  name: 'main-footer',
  data () {
    return {
      holaLinks: [
        {
          name: this.$t('xCommunications'),
          links: [
            {
              name: this.$t('xCommunicationsInvestorRelations'),
              href: 'investor-relations',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('xCommunicationsMediaRequest'),
              href: 'media-request',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('xCommunicationsOurPositionPrivacy'),
              href: 'sayhola-and-security',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('xCommunicationsOurPosition'),
              href: 'our-position-on-cyberbullying',
              target: '_blank',
              type: 'internal'
            }
          ]
        },
        {
          name: this.$t('xCommunicationsKnowledgeBase'),
          links: [
            {
              name: this.$t('faqWord'),
              href: 'faq',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('holaBlog'),
              href: this.$route.fullPath.includes('/es') ? 'https://moreinfo.privateapp.io/es' : 'https://moreinfo.privateapp.io/en',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('paidServices'),
              href: 'packages',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('multiapp'),
              href: this.$route.fullPath.includes('/es') ? 'https://moreinfo.privateapp.io/es/category/sync/ ' : 'https://moreinfo.privateapp.io/en/category/sync/ ',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('opportunities'),
          links: [
            {
              name: this.$t('programsFreelancerProgram'),
              href: 'freelancer-program',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('footerPrivateAppJobsPage'),
              href: 'jobs',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('rewardsProgram'),
              href: 'rewards',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('despaxoDrivers'),
              href: 'https://despaxo.privateapp.io/ ',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('generalTerms'),
          links: [
            {
              name: this.$t('termsTermsUse'),
              href: 'terms-of-use',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('termsPrivacyPolicy'),
              href: 'privacy-policy',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('termsCommunityStandards'),
              href: 'community-standards',
              target: '_blank',
              type: 'internal'
            }
          ]
        },
        {
          name: this.$t('apps'),
          links: [
            {
              name: 'iOS',
              href: 'https://privateapp.io/ios',
              target: '_blank',
              type: 'external'
            },
            {
              name: 'Android',
              href: 'https://privateapp.io/android',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('web'),
              href: 'https://dashboard.privateapp.io/sign-in',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('browserExtensions'),
              href: '',
              target: '_self',
              type: 'external'
            },
            {
              name: `${this.$t('desktop')} (Coming Soon)`,
              href: '#',
              target: '_self',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('premiumTools'),
          links: [
            {
              name: this.$t('premiumToolsSayHolaLiveChatPlugin'),
              href: 'live-chat-plugin',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('blogSyncPlugin'),
              href: 'https://gitlab.com/xgroupdevelopers/sayhola-blog-wp-plugin',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('together'),
              href: 'https://together.privateapp.io/',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('chatbotConstructor'),
              href: 'https://despaxo.admin.privateapp.io/',
              target: '_blank',
              type: 'external'
            },
            {
              name: this.$t('premiumToolsLiveLeagueApp'),
              href: 'https://liveleagueapp.com/',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('earnPrograms'),
          links: [
            {
              name: this.$t('BDTLAgencyProgram'),
              href: 'agency-program',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('despaxoBot'),
              href: 'https://chatbots.privateapp.io/ ',
              target: '_blank',
              type: 'external'
            }
          ]
        },
        {
          name: this.$t('extendetTerms'),
          links: [
            {
              name: this.$t('termsAdsPolicy'),
              href: 'ads-policy',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('despaxoPolicy'),
              href: 'despaxo-terms',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('agencyTermsNew'),
              href: 'agency-terms',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('termsLoyaltyRewardsTerms'),
              href: 'loyalty-rewards-terms',
              target: '_blank',
              type: 'internal'
            },
            {
              name: this.$t('despaxoDriverPolicy'),
              href: 'despaxo-program',
              target: '_blank',
              type: 'internal'
            }
          ]
        }
      ]
    }
  },
  computed: {
    youtubeIco
  }
}
