import { isMobile } from '@/helpers/variables/index.js'

export default {
  name: 'app-banner',
  data () {
    return {
      isMobileDevice: null,
      appLink: ''
    }
  },
  created () {
    if (isMobile.Android()) {
      this.isMobileDevice = 'android'
      this.appLink = 'https://play.google.com/store/apps/details?id=com.xgroup.hola.hola'
    } else if (isMobile.iOS()) {
      this.isMobileDevice = 'ios'
      this.appLink = 'https://apps.apple.com/us/app/sayhola/id1474217306?ls=1'
    }
  },
  methods: {
    closeAppBanner () {
      this.isMobileDevice = null
    }
  }
}
