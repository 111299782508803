const state = {
  translations: null
}

const getters = {
  translations: state => state.translations
}

export default {
  state,
  getters
}
