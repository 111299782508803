import api from '@/api/policy'

const state = {
  privacyPolicy: null,
  privacyPolicyLoading: false,
  termsOfUse: null,
  termsOfUseLoading: false
}

const getters = {
  privacyPolicy: state => state.privacyPolicy,
  privacyPolicyLoading: state => state.privacyPolicyLoading,
  termsOfUse: state => state.termsOfUse,
  termsOfUseLoading: state => state.termsOfUseLoading
}

const actions = {
  async getPrivacy ({ commit }, lang) {
    try {
      commit('CHANGE_PRIVACY_LOADING', true)
      const { data } = await api.getPrivacyPolicy(lang)
      commit('SET_PRIVACY_POLICY', data)
    } finally {
      commit('CHANGE_PRIVACY_LOADING', false)
    }
  },
  async getTerms ({ commit }, lang) {
    try {
      commit('CHANGE_TERMS_LOADING', true)
      const { data } = await api.getTerms(lang)
      commit('SET_TERMS', data)
    } finally {
      commit('CHANGE_TERMS_LOADING', false)
    }
  }
}

const mutations = {
  SET_PRIVACY_POLICY (state, data) {
    state.privacyPolicy = data
  },
  CHANGE_PRIVACY_LOADING (state, status) {
    state.privacyPolicyLoading = status
  },
  SET_TERMS (state, data) {
    state.termsOfUse = data
  },
  CHANGE_TERMS_LOADING (state, status) {
    state.termsOfUseLoading = status
  }
}

export default { state, getters, actions, mutations }
