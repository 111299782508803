import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'terms-of-use',
  data () {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.termsOfUse')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      termsOfUse: 'policy/termsOfUse'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ]),
    currentLang () {
      return this.currentLanguage.requestPrefix
    }
  },
  created () {
    this.getTermsOfUse()
  },
  methods: {
    ...mapActions({
      getTerms: 'policy/getTerms'
    }),
    getTermsOfUse () {
      this.getTerms(this.currentLang)
    }
  }
}
