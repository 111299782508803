import Vue from 'vue'
import SwiperCore, { Swiper as SwiperClass, Pagination, Navigation, EffectFade } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import '../../assets/scss/swiper-bundle.scss'
import '../../assets/scss/effect-fade.scss'
import { mapMutations } from 'vuex'

SwiperCore.use([EffectFade])

SwiperClass.use([Pagination, Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
const SIGNIN_URL = process.env.VUE_APP_ROOT_HOLA

export default {
  name: 'home',
  data () {
    return {
      currentSlide: 0,
      isLastSlide: false,
      swiperOptions: {
        autoHeight: true,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          clickable: true,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    signInUrl () {
      return SIGNIN_URL
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.main')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  methods: {
    ...mapMutations({
      changeShowRecoveryPopup: 'auth/changeShowRecoveryPopup'
    }),
    changeSlide (index) {
      this.isLastSlide = index.activeIndex + 1 === index.slides.length
    }
  }
}
