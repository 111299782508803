import apiReq from './request'

const HOLA_CHAT_API = process.env.VUE_APP_CHAT_URL

export default {
  getMatrixCredentials () {
    return apiReq.get(`${HOLA_CHAT_API}/chat/matrix`)
  },
  saveMatrixCredentials (payload) {
    return apiReq.post(`${HOLA_CHAT_API}/chat/matrix`, payload)
  },
  updateMatrixCredentials (payload) {
    return apiReq.put(`${HOLA_CHAT_API}/chat/matrix`, payload)
  }
}
