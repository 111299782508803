<template>
  <div class="main-popup">
    <div class="main-popup__wrap">
      <div class="main-popup__bg" @click="closePopup()"></div>
      <div class="main-popup__container" :style="{'max-width': `${maxWidth}px`}">
        <div class="main-popup__content">
          <div class="main-popup__close">
            <button class="close-button" @click="closePopup()">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L3.24958 4.66379L0.292894 7.62048C-0.0976309 8.011 -0.0976309 8.64417 0.292894 9.03469C0.683418 9.42522 1.31658 9.42522 1.70711 9.03469L4.66379 6.07801L7.62048 9.03469C8.011 9.42522 8.64417 9.42522 9.03469 9.03469C9.42522 8.64417 9.42522 8.011 9.03469 7.62048L6.07801 4.66379L9.03469 1.70711C9.42522 1.31658 9.42522 0.683418 9.03469 0.292893C8.64417 -0.0976311 8.011 -0.0976311 7.62048 0.292893L4.66379 3.24958L1.70711 0.292893Z"
                      fill="white"/>
              </svg>
            </button>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popup-wrap-main',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 500
    }
  },
  methods: {
    closePopup () {
      this.$emit('close-popup')
    }
  }
}
</script>
<style lang="scss" scoped>

  .main-popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 150;
    overflow-y: auto;

    &__bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(97, 97, 97, 0.6);
      backdrop-filter: blur(10px);
    }

    &__wrap {
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      overflow-y: auto;
    }

    &__container {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      max-width: 640px;
      background: #010203;
      position: relative;
      z-index: 2;
      border: 1px solid $black;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
    }

    &__top {
      width: 100%;
      border-bottom: solid 2px $black;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 25px 15px;
      margin-bottom: 20px;

      &-title {
        font-family: Gill Sans Pro Cyrillic;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: #fff;
        max-width: 93%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__close {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 0;
      width: 41px;
      height: 41px;
      z-index: 3;

      .close-button {
        width: 41px;
        height: 41px;
        background: none;
        border: none;
        padding: 0;
        position: relative;
        z-index: 2;
        background: #343434;
        border-radius: 50%;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .main-popup__top {
      overflow: hidden;
    }
  }

  @media screen and (max-width: 900px) {
    .main-popup {
      &__bg {
        position: fixed;
      }
      &__wrap {
        height: 100vh;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
      }
      &__wrap {
        overflow-y: auto;
      }
    }
    .main-popup__top {
      padding: 20px 10px;
    }
    .main-popup__top-title {
      font-size: 20px;
      line-height: 24px;
    }
    .main-popup__close {
      top: 20px;
      right: 10px;
      width: 30px;
      height: 30px;
    }
    .main-popup__close .close-button {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .main-popup__top {
      padding: 25px 30px;
    }
  }
</style>
