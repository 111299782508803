import Vue from 'vue'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueHead from 'vue-head'
import VueTelInput from 'vue-tel-input'
import Toasted from 'vue-toasted'
import 'vue-tel-input/dist/vue-tel-input.css'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import VModal from 'vue-js-modal'

// Use plugin
Vue.use(Toasted, {
  duration: 5000
})
Vue.use(VueYouTubeEmbed)
Vue.use(VueHead)
Vue.use(VueTelInput)
Vue.use(Vuelidate)
Vue.use(VTooltip)
Vue.use(VModal)
