import accordion from './components/accordion/index.vue'

export default {
  name: 'agency-program',
  data () {
    return {}
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('BDTLAgencyProgram')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    faqListCount () {
      return parseInt(this.$t('agencyFaqList.itemsCount'))
    }
  },
  components: {
    accordion
  },
  mounted () {
    if (this.$route.query && this.$route.query.scrollTo) {
      setTimeout(() => {
        this.scrollToSection()
      }, 500)
    }
  },
  methods: {
    scrollToSection () {
      this.$refs.faqList.scrollIntoView({ behavior: 'smooth' })
      this.$router.replace({ query: null })
    }
  }
}
