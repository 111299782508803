// system
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER'
export const FIX_BODY = 'FIX_BODY'
export const POPUP_TEXT_CONTENT = 'POPUP_TEXT_CONTENT'
export const SHOW_POPUP_TEXT_CONTENT = 'SHOW_POPUP_TEXT_CONTENT'
export const CHANGE_CURRENT_LANG = 'CHANGE_CURRENT_LANG'
export const CHANGE_SHOW_LANG_LIST = 'CHANGE_SHOW_LANG_LIST'

// packages
export const CHANGE_VIDEO_POPUP_INFO = 'CHANGE_VIDEO_POPUP_INFO'
export const CHANGE_SHOW_VIDEO_POPUP = 'CHANGE_SHOW_VIDEO_POPUP'

// auth
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN'

export const CHANGE_ERROR = 'CHANGE_ERROR'
