import api from '@/api'
import { base64ToFile } from '@/helpers'

const state = {
  updateUserLoading: false,
  createProfileLoading: false,
  step: 1,
  socialProfiles: null,
  socialProfilesLoading: false
}

const getters = {
  updateUserLoading: (state) => state.updateUserLoading,
  createProfileLoading: (state) => state.createProfileLoading,
  step: (state) => state.step,
  socialProfiles: state => state.socialProfiles,
  socialProfilesLoading: state => state.socialProfilesLoading
}

const actions = {
  async saveProfile ({ commit }, params) {
    try {
      commit('changeUpdateUserLoading', true)
      await api.user.saveProfile(params)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeUpdateUserLoading', false)
    }
  },
  async createProfile ({ commit }, data) {
    try {
      commit('changeCreateProfileLoading', true)
      const formData = new FormData()
      for (const key in data) {
        // if (data[key] instanceof File) {
        //   formData.append(key, data[key])
        // } else {
        //   formData.set(key, data[key])
        // }
        if (key === 'picture') {
          formData.set('picture', base64ToFile(data.picture, key))
        } else {
          formData.set(key, data[key])
        }
      }
      await api.user.createSocialProfile(formData)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeCreateProfileLoading', false)
    }
  },
  async checkUsername (_, nickname) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await api.user.checkUsername(nickname)
    } catch (e) {
      throw e
    }
  },
  async checkHolaUsername (_, nickname) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await api.user.checkHolaUsername(nickname)
    } catch (e) {
      throw e
    }
  },
  async getSocialSpaceProfiles ({ commit }, params) {
    try {
      commit('changeProfilesLoading', true)
      const response = await api.user.getSocialSpaceProfiles(params)
      commit('setSocialProfiles', response)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeProfilesLoading', false)
    }
  }
}

const mutations = {
  changeUpdateUserLoading (state, status) {
    state.updateUserLoading = status
  },
  changeCreateProfileLoading (state, status) {
    state.createProfileLoading = status
  },
  changeStep (state, step) {
    state.step = step
  },
  changeProfilesLoading (state, status) {
    state.socialProfilesLoading = status
  },
  setSocialProfiles (state, data) {
    state.socialProfiles = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
