import { $http } from '@/utils/http'
import api from '@/api'
import sha512 from 'js-sha512'

const state = {
  isAuthenticated: false,
  isLoading: false,
  otpResponse: null,
  isLoadingConfirm: false,
  isLoadingLogin: false,
  offerData: null,
  offerDataLoading: false,
  package: null,
  packagesLoading: false,
  accessToken: null,
  profile: null,
  profileLoading: false,
  isShowRecoveryPopup: false,
  registrationPopup: {
    type: 'offer',
    show: false
  },
  qrCodeLoginPopup: {
    type: 'offer',
    show: false
  },
  authByCodeLoading: false
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isLoading: (state) => state.isLoading,
  otpResponse: state => state.otpResponse,
  isLoadingConfirm: state => state.isLoadingConfirm,
  isLoadingLogin: state => state.isLoadingLogin,
  offerData: state => state.offerData,
  offerDataLoading: state => state.offerDataLoading,
  package: state => state.package,
  packagesLoading: state => state.packagesLoading,
  accessToken: state => state.accessToken,
  profile: state => state.profile,
  profileLoading: state => state.profileLoading,
  isShowRecoveryPopup: state => state.isShowRecoveryPopup,
  registrationPopup: state => state.registrationPopup,
  qrCodeLoginPopup: state => state.qrCodeLoginPopup,
  authByCodeLoading: state => state.authByCodeLoading
}

const actions = {
  async getOtp ({ commit }, payload) {
    try {
      commit('changeLoading', true)
      const prefix = 'sc!FdyA7^2PP^Lee&-5!Y%H-Y(V!9aC(Hs(map9#VfDanfQ+bcu$8r'
      const sufix = 'Af)8D]vc~stz>v{/8U7L4a)K+NH9H}cfUqYaZ=~*Z/n?94#Pm-y*'
      const secret = sha512(prefix + payload.phone + sufix)
      const data = {
        phone: payload.phone,
        secret: secret
      }
      const response = await $http.post(`${process.env.VUE_APP_ROOT_AUTH}/auth/um08abvemDdMyBfXMaqC`, data)
      commit('saveOtpResponse', {
        ...data,
        ...response.data.data
      })
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  },
  async confirmOtp ({ commit }, payload) {
    try {
      commit('changeConfirmLoading', true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_AUTH}/auth/user/auth`, payload)
      commit('authSaveToken', response.data.data.token)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeConfirmLoading', false)
    }
  },
  async getOfferInfo ({ commit }, slug) {
    try {
      commit('changeOfferLoading', true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_OFFERS}/payment/offers/detail?slug=${slug}`)
      commit('setOfferData', response.data.data)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeOfferLoading', false)
    }
  },
  async getPackages ({ commit }, language = 'en') {
    try {
      commit('changePackagesLoading', true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_OFFERS}/payment/user/packages`, { params: { language } })
      commit('setPackages', response.data.data)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changePackagesLoading', false)
    }
  },
  async logout ({ dispatch, state }, allSessions = false) {
    dispatch('cleanUp')
  },
  cleanUp ({ commit, dispatch }) {
    commit('clearAuth')
  },
  async getMyProfile ({ commit }, language = 'en') {
    try {
      commit('changeProfileLoading', true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_OFFERS}/user/user/my_profile`)
      commit('setProfile', response.data.data)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeProfileLoading', false)
    }
  },
  async requestMagicRecovery ({ state }, email) {
    const params = {
      email,
      device: {
        id: '73617948-6f6c-6120-426c-6f6720777000',
        type: 'web'
      }
    }
    await api.auth.requestMagicRecovery(params)
  },
  async updatePhone ({ commit }, payload) {
    try {
      const { data } = await api.auth.updatePhone(payload)
      return data
    } catch (err) {
      throw err.response
    }
  },
  async authByCode ({ commit }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.authByCode(payload)
      if (response.data && Object.keys(response.data).length === 0 && Object.getPrototypeOf(response.data) === Object.prototype) {

      } else {
        commit('authSaveTokenCase', response.data.token)
      }
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  }
}

const mutations = {
  authSaveToken (state, token) {
    localStorage.setItem('accessToken', token.access_token)
    state.accessToken = token.access_token
    state.isAuthenticated = true
  },
  authSaveTokenCase (state, token) {
    localStorage.setItem('accessToken', token.accessToken)
    state.accessToken = token.accessToken
    state.isAuthenticated = true
  },
  setToken (state, token) {
    state.accessToken = token
  },
  changeLoading (state, status) {
    state.isLoading = status
  },
  changeConfirmLoading (state, status) {
    state.isLoadingConfirm = status
  },
  changeLoginLoading (state, status) {
    state.isLoadingLogin = status
  },
  saveOtpResponse (state, params = null) {
    state.otpResponse = params
  },
  changeAuthStatus (state, status) {
    state.isAuthenticated = status
  },
  setOfferData (state, data) {
    state.offerData = data
  },
  changeOfferLoading (state, status) {
    state.offerDataLoading = status
  },
  clearAuth (state) {
    state.accessToken = null
    state.user = null
    state.profile = null
    state.otpResponse = null
    state.isAuthenticated = false
    localStorage.removeItem('accessToken')
    // window.location.reload()
  },
  setPackages (state, data) {
    state.packages = data
  },
  changePackagesLoading (state, status) {
    state.packagesLoading = status
  },
  setProfile (state, data) {
    state.profile = data
  },
  changeProfileLoading (state, status) {
    state.profileLoading = status
  },
  changeShowRecoveryPopup (state, status) {
    state.isShowRecoveryPopup = status
  },
  changeRegistrationPopup (state, props) {
    state.registrationPopup.show = props.show
    state.registrationPopup.type = props.type
  },
  changeLoginByQRPopup (state, props) {
    state.qrCodeLoginPopup.show = props.show
    state.qrCodeLoginPopup.type = props.type
  },
  changeAuthByCodeLoading (state, status) {
    state.authByCodeLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
