import { createToken } from 'vue-stripe-elements-plus'
import api from '@/api'

import {
  CHANGE_VIDEO_POPUP_INFO,
  CHANGE_SHOW_VIDEO_POPUP
} from '../mutation-types'

import {
} from '../action-types'

const state = {
  isShowVideoPopup: false,
  buyPackageResult: null,

  videoPopup: {
    videoId: null
  }
}

const getters = {
  isShowVideoPopup: state => state.isShowVideoPopup,
  buyPackageResult: state => state.buyPackageResult,

  videoPopup: state => state.videoPopup
}

const actions = {
  // eslint-disable-next-line camelcase
  async buy ({ rootGetters, commit }, { name, package_id, special_offer_id }) {
    let stripeResp
    if (name) {
      stripeResp = await createToken({ name })
    }
    const params = {
      package_id,
      special_offer_id
    }
    if (stripeResp) {
      // const { token: { id } } = stripeResp
      // params.charge_token = id
      params.charge_token = 'tok_visa' // for test
    } else {
      params.charge_token = 'free'
    }
    const data = await api.payment.buyPackage(params)
    commit('saveBuyPackageResult', data)
  }
}

const mutations = {
  [CHANGE_VIDEO_POPUP_INFO] (state, info) {
    state.videoPopup = info
  },
  [CHANGE_SHOW_VIDEO_POPUP] (state, status) {
    state.isShowVideoPopup = status
  },
  saveBuyPackageResult (state, data) {
    state.buyPackageResult = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
