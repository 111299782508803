import accordion from './components/accordion/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'freelancer-program',
  data () {
    return {
      activeVideoLink: ''
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Hola ',
        separator: '|',
        complement: this.$t('pagesTitle.freelancerProgram')
      }
    },
    link: function () {
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ]),
    faqListCount () {
      return parseInt(this.$t('freelancerProgramPage.faqList.itemsCount'))
    }
  },
  components: {
    accordion
  },
  created () {
    this.setActiveVideo()
  },
  mounted () {
    if (this.$route.query && this.$route.query.scrollTo) {
      setTimeout(() => {
        this.scrollToSection()
      }, 500)
    }
  },
  methods: {
    scrollToSection () {
      this.$refs.faqList.scrollIntoView({ behavior: 'smooth' })
      this.$router.replace({ query: null })
    },
    setActiveVideo () {
      switch (this.currentLanguage.urlPrefix) {
        case 'fr': {
          this.activeVideoLink = '_luJSvT9nCU'
          break
        }
        case 'zh': {
          this.activeVideoLink = 'TOVYqInC7s0'
          break
        }
        case 'en': {
          this.activeVideoLink = 'wL6VHYwfoDo'
          break
        }
        case 'es': {
          this.activeVideoLink = '-YoZhQhyrRs'
          break
        }
        case 'ru': {
          this.activeVideoLink = 'uJb0lRXk6Q8'
          break
        }
        case 'pt': {
          this.activeVideoLink = 'YrumT4-bJ9g'
          break
        }
        case 'jp': {
          this.activeVideoLink = 'pEFUypyyMNk'
          break
        }
        case 'de': {
          this.activeVideoLink = 's3egs7SaJ4U'
          break
        }
        default: {
          break
        }
      }
    }
  }
}
