import { VueSlideToggle } from 'vue-slide-toggle'

export default {
  name: 'accordion',
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  created () {
    if (this.index === 1) {
      this.expanded = true
    }
  },
  methods: {
    toggleCardState () {
      this.expanded = !this.expanded
    }
  }
}
