import StripeForm from './components/stripe-form/index.vue'
import ChatUserStep from '@/modules/jobs/components/ChatUserStep.vue'
import SocialSPaceUserStep from '@/modules/jobs/components/SocialSpaceUserStep.vue'
import { isMobile } from '@/helpers/variables/index.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

const HOLA_URL = process.env.VUE_APP_ROOT_HOLA

export default {
  components: {
    StripeForm,
    ChatUserStep,
    SocialSPaceUserStep
  },
  data () {
    return {
      params: '',
      appLink: '',
      successResult: false
    }
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      offerData: 'auth/offerData',
      offerDataLoading: 'auth/offerDataLoading',
      profile: 'auth/profile',
      buyPackageResult: 'packages/buyPackageResult',
      accessToken: 'auth/accessToken',
      step: 'offers/step'
    }),
    redirectURL () {
      return HOLA_URL + '/?token=' + this.accessToken
    },
    offerSlug () {
      return this.$route.params.slug
    }
  },
  watch: {
    buyPackageResult: {
      immediate: true,
      handler (newVal) {
        if (newVal && newVal.data.status === 'success') {
          this.successResult = true
        }
      }
    }
  },
  created () {
    this.getOffer()
    this.detectMobile()

    if (this.isAuthenticated) {
      this.changeStep(2)
    } else {
      this.changeStep(1)
    }
  },
  methods: {
    ...mapMutations({
      changeStep: 'offers/changeStep',
      changeShowRecoveryPopup: 'auth/changeShowRecoveryPopup',
      changeRegistrationPopup: 'auth/changeRegistrationPopup',
      changeLoginByQRPopup: 'auth/changeLoginByQRPopup'
    }),
    ...mapActions({
      getOfferData: 'auth/getOfferInfo',
      getPackages: 'auth/getPackages'
    }),
    getOffer () {
      if (this.offerSlug) {
        this.getOfferData(this.offerSlug).then(() => {}).catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: 'home' }).catch(() => {})
          }
          if (error.response.data.data.special_offer_id) {
            this.$toasted.error(this.$t('offerExpired'))
            this.$router.push({ name: 'home' }).catch(() => {})
          }
        })
      }
    },
    detectMobile () {
      if (isMobile.iOS()) {
        this.isMobileDevice = 'ios'
        this.appLink = 'https://apps.apple.com/us/app/sayhola/id1474217306?ls=1'
      } else {
        this.isMobileDevice = 'android'
        this.appLink = 'https://play.google.com/store/apps/details?id=com.xgroup.hola.hola'
      }
    },
    openRegistrationPopup () {
      this.changeRegistrationPopup({ show: true, type: 'offer' })
    },
    openQRCodePopup () {
      this.changeLoginByQRPopup({ show: true, type: 'offer' })
    }
  }
}
