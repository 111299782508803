import Vue from 'vue'
import Router from 'vue-router'

// modules
import Home from '@/modules/home/index.vue'
import InvestorRelations from '@/modules/footer/x-communications/investor-relations/index.vue'
import MediaRequest from '@/modules/footer/x-communications/media-request/index.vue'
import SayholaAndSecurity from '@/modules/footer/x-communications/sayhola-and-security/index.vue'
import FAQ from '@/modules/footer/x-communications/faq/index.vue'
import OurPositionOnCyberbullying from '@/modules/footer/x-communications/our-position-on-cyberbullying/index.vue'
import AdsPolicy from '@/modules/footer/terms/ads-policy/index.vue'
import DespaxoTerms from '@/modules/footer/terms/despaxo-terms/index.vue'
import OperatingDespaxo from '@/modules/footer/terms/operating-despaxo/index.vue'
import DespaxoDefinitions from '@/modules/footer/terms/despaxo-definitions/index.vue'
import AdministrativeStatement from '@/modules/footer/terms/administrative-statement/index.vue'
import PrivacyPolicy from '@/modules/footer/terms/privacy-policy/index.vue'
import AdvertiseWithHola from '@/modules/footer/programs/advertise-with-hola/index.vue'
import CommunityStandards from '@/modules/footer/terms/community-standards/index.vue'
import TermsOfUse from '@/modules/footer/terms/terms-of-use/index.vue'
import LoyaltyRewardsTerms from '@/modules/footer/terms/loyalty-rewards-terms/index.vue'
import FreelancerProgram from '@/modules/footer/programs/freelancer-program/index.vue'
import AgencyProgram from '@/modules/footer/programs/agency-program/index.vue'
import DespaxoProgram from '@/modules/footer/programs/despaxo-program/index.vue'
import AgencyTerms from '@/modules/footer/terms/agency/index.vue'
import Rewards from '@/modules/footer/programs/rewards/index.vue'
import LiveChatPlugin from '@/modules/footer/premium-tools/live-chat-plugin/index.vue'
import Packages from '@/modules/packages/index.vue'
import Offers from '@/modules/offers/index.vue'
import Jobs from '@/modules/jobs/index.vue'
import NotFound from '@/modules/not-found/index.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?',
      name: 'home',
      component: Home
    },
    {
      path: '/:lang?/investor-relations',
      name: 'investor-relations',
      component: InvestorRelations
    },
    {
      path: '/:lang?/media-request',
      name: 'media-request',
      component: MediaRequest
    },
    {
      path: '/:lang?/sayhola-and-security',
      name: 'sayhola-and-security',
      component: SayholaAndSecurity
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      component: FAQ
    },
    {
      path: '/:lang?/our-position-on-cyberbullying',
      name: 'our-position-on-cyberbullying',
      component: OurPositionOnCyberbullying
    },
    {
      path: '/:lang?/ads-policy',
      name: 'ads-policy',
      component: AdsPolicy
    },
    {
      path: '/:lang?/advertise-with-hola',
      name: 'advertise-with-hola',
      component: AdvertiseWithHola
    },
    {
      path: '/:lang?/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy
    },
    {
      path: '/:lang?/community-standards',
      name: 'community-standards',
      component: CommunityStandards
    },
    {
      path: '/:lang?/terms-of-use',
      name: 'terms-of-use',
      component: TermsOfUse
    },
    {
      path: '/:lang?/loyalty-rewards-terms',
      name: 'loyalty-rewards-terms',
      component: LoyaltyRewardsTerms
    },
    {
      path: '/:lang?/freelancer-programs',
      name: 'freelancer-program',
      component: FreelancerProgram
    },
    {
      path: '/:lang?/agency-program',
      name: 'agency-program',
      component: AgencyProgram
    },
    {
      path: '/:lang?/despaxo-program',
      name: 'despaxo-program',
      component: DespaxoProgram
    },
    {
      path: '/:lang?/operating-despaxo',
      name: 'operating-despaxo',
      component: OperatingDespaxo
    },
    {
      path: '/:lang?/despaxo-definitions',
      name: 'despaxo-definitions',
      component: DespaxoDefinitions
    },
    {
      path: '/:lang?/despaxo-terms',
      name: 'despaxo-terms',
      component: DespaxoTerms
    },
    {
      path: '/:lang?/administrative-statement',
      name: 'administrative-statement',
      component: AdministrativeStatement
    },
    {
      path: '/:lang?/agency-terms',
      name: 'agency-terms',
      component: AgencyTerms
    },
    {
      path: '/:lang?/rewards',
      name: 'rewards',
      component: Rewards
    },
    {
      path: '/:lang?/live-chat-plugin',
      name: 'live-chat-plugin',
      component: LiveChatPlugin
    },
    {
      path: '/:lang?/packages',
      name: 'packages',
      component: Packages
    },
    {
      path: '/:lang?/offers/:slug',
      name: 'offers',
      component: Offers
    },
    {
      path: '/:lang?/jobs',
      name: 'jobs',
      component: Jobs
    },
    {
      path: '/:lang?/index.php/xhola-affiliate-program',
      name: 'affiliate',
      redirect: {
        name: 'freelancer-program'
      }
    },
    {
      path: '/:lang?/login-bridge',
      name: 'login-bridge',
      component: () => import('@/modules/bridge-connection/index.vue')
    },
    {
      path: '/:lang?/ios',
      name: 'ios',
      beforeEnter () {
        window.location.href = 'https://apps.apple.com/us/app/sayhola/id1474217306?ls=1'
      }
    },
    {
      path: '/:lang?/android',
      name: 'android',
      beforeEnter () {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.xgroup.hola.hola'
      }
    },
    {
      path: '/:lang?/*',
      name: 'not-found',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // const el = document.querySelector('.app')
    if (to.path !== from.path) {
      // if (savedPosition) {
      //   return savedPosition
      // } else {
      //   el.scrollLeft = 0
      //   el.scrollTop = 0
      return { x: 0, y: 0 }
      // }
    }
  }
})

export default router
