import { $http } from '@/utils/http'
import apiReq from './request'
const BASE_URL = `${process.env.VUE_APP_ROOT_AUTH}/user`
const SOCIAL_URL = `${process.env.VUE_APP_ROOT_AUTH}/social`

export default {
  saveProfile (data) {
    return $http.patch(`${BASE_URL}/user/my_profile`, data)
  },
  createSocialProfile (data) {
    return apiReq.post(`${SOCIAL_URL}/profile/my/`, data)
  },
  checkUsername (nickname) {
    return apiReq.post(`${SOCIAL_URL}/profile/my/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  },
  checkHolaUsername (nickname) {
    return apiReq.post(`${BASE_URL}/user/check-unique-nickname?nickname=${nickname}`, { nickname: nickname })
  },
  getSocialSpaceProfiles (data) {
    return apiReq.get(`${SOCIAL_URL}/profile/my/?my=true&limit=50`)
  }
}
