<template>
  <div id="app" class="app">
    <div class="app-container">
      <app-banner />
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <div class="wrapper-content">
            <transition name="component-fade" mode="out-in">
              <router-view/>
            </transition>
          </div>
        </div>
        <div class="wrapper-bottom">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
    <Video />
  </div>
</template>

<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import mainHeader from '@/components/features/header/index.vue'
import mainFooter from '@/components/features/footer/index.vue'
import hidden from '@/components/hidden/index.vue'
import appBanner from '@/components/features/app-banner/index.vue'
import Video from '@/components/modals/Video.vue'

export default {
  name: 'App',
  data () {
    return {}
  },
  components: {
    mainHeader,
    mainFooter,
    hidden,
    appBanner,
    Video
  },
  watch: {
    error: {
      handler (item) {
        if (item.show) {
          this.$toasted.error(item.message)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      error: 'system/error',
      isAuthenticated: 'auth/isAuthenticated',
      profile: 'profile/profile'
    })
  },
  created () {
    if (localStorage.getItem('accessToken')) {
      this.changeAuthStatus(true)
      this.setToken(localStorage.getItem('accessToken'))
      this.getProfile().then(() => {
        this.changeStep(2)
      })
    }
  },
  methods: {
    ...mapMutations({
      changeAuthStatus: 'auth/changeAuthStatus',
      setToken: 'auth/setToken',
      changeStep: 'user/changeStep'
    }),
    ...mapActions({
      getProfile: 'auth/getMyProfile'
    })
  }
}
</script>
