const state = {
  step: 1
}

const getters = {
  step: (state) => state.step
}

const mutations = {
  changeStep (state, step) {
    state.step = step
  }
}
export default {
  state,
  getters,
  mutations
}
