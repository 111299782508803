// import components
import Vue from 'vue'

import Loader from '@/components/misc/loader.vue'
import smallLoader from '@/components/misc/small-loader.vue'
import PopupWrap from '@/components/misc/popup-wrap.vue'
import VueQRCodeComponent from 'vue-qrcode-component'

// use global components
Vue.component('loader', Loader)
Vue.component('small-loader', smallLoader)
Vue.component('popup-wrap', PopupWrap)
Vue.component('qr-code', VueQRCodeComponent)
