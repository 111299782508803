<template>
  <modal
    :classes="$style.modal"
    :name="modalName"
    width="560px"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    ref="modal"
    @click.native="$modal.hide(modalName)"
  >
    <!-- <BaseIcon
      custom name="close"
      :class="$style.closeBtn"
      @click="$modal.hide(modalName)"
    /> -->
    <video
      v-if="!isYoutube"
      controls
      :src="src"
      :class="$style.video"
      @click.stop
    ></video>
    <iframe
      v-else
      width="560" height="315"
      :src="src"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </modal>
</template>

<script>
export default {
  name: 'video-modal',
  data () {
    return {
      width: 300,
      modalName: 'video',
      src: null,
      isYoutube: false,
      onDone: () => {}
    }
  },
  methods: {
    async beforeOpen ({ params: { src, isYoutube = false, onDone = () => {} } }) {
      this.isYoutube = isYoutube
      this.src = src
      this.onDone = onDone
    },
    beforeClose () {
      this.src = null
      this.onDone = () => {}
    },
    hide () {
      this.$modal.hide(this.modalName)
    }
  }
}
</script>

<style module lang="scss">
.modal {
  background: $transparent;
  // box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  // border: 1px solid $border-color;
  font-weight: 400;
  border-radius: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
}
// .closeBtn {
//   cursor: pointer;
//   color: $text-dark;
//   font-size: 20px;
//   position: absolute;
//   right: 15px;
//   top: 15px;
//   transition: color .3s;
//   &:hover {
//     color: $text-darkest;
//   }
// }
.video {
  max-width: 100%;
  max-height: 100vh;
  margin: 0 auto;
}

</style>
