import { mapGetters, mapMutations } from 'vuex'
import TooltipLayout from '@/components/atoms/tooltip-layout/index.vue'
import burger from '@/components/atoms/burger/index.vue'
const SIGNIN_URL = process.env.VUE_APP_ROOT_HOLA

export default {
  name: 'main-header',
  data () {
    return {
      isOpenMenu: false,
      isActive: false
    }
  },
  components: {
    TooltipLayout,
    burger
  },
  computed: {
    ...mapGetters({
      showLangList: 'system/showLangList',
      isShowLanguageList: 'system/isShowLanguageList',
      userProfile: 'auth/profile'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ]),
    signInUrl () {
      return SIGNIN_URL
    },
    showBurger () {
      return this.$route.name !== 'login-bridge'
    }
  },
  methods: {
    ...mapMutations({
      changeShowLangList: 'system/CHANGE_SHOW_LANG_LIST',
      logout: 'auth/clearAuth'
    }),
    changeHeaderLogo () {
      if (this.$route.name === 'home') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.push({ name: 'home' }).catch(() => { console.log() })
      }
    },
    changeLanguage (language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    openMenu () {
      this.isOpenMenu = !this.isOpenMenu
    },
    logoutProfile () {
      this.logout()
      this.$router.push({ name: 'home' }).catch(() => {})
      setTimeout(() => {
        window.location.reload()
      }, 800)
    }
  }
}
