<template>
  <div class="accordion" v-if="item">
    <div class="accordion-head" @click="toggleCardState" :class="{ 'active': expanded}">
      <div class="accordion-title">
        {{ item.itemTitle }}
      </div>
      <div class="accordion-icon">
        <div class="pluss">
          <div class="toggle" :class="{'open': !expanded}"></div>
        </div>
      </div>
    </div>

    <VueSlideToggle
        :open="expanded"
        tag="div"
        :duration="450">
      <div class="accordion-content">
        <h3> {{ item.itemTitle }} {{ $t('responsibilitieInclude') }}: </h3>
        <ul class="accordion-list responsibilities-list">
          <li class="accordion-item" v-for="(item) in item.itemResponsibilitiesList" :key="item.id">
            {{ item.content }}
          </li>
        </ul>
        <h3>
          {{ $t('jobBrief') }}
        </h3>
        <ul class="accordion-list brief-list">
          <li class="accordion-item" v-for="(position) in item.itemJobsBriefList" :key="position.id">
            {{ position.content }}
          </li>
        </ul>
        <div class="accordion-action">
          <a href="" @click.prevent="showJobRegisterPopup">
            {{ $t('applyToJobPosition') }}
          </a>
        </div>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle'
import { mapMutations } from 'vuex'

export default {
  name: 'JobsItem',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  methods: {
    ...mapMutations({
      changeShowJobRegisterPopup: 'home/changeShowJobsPopup'
    }),
    toggleCardState () {
      this.expanded = !this.expanded
    },
    showJobRegisterPopup () {
      this.changeShowJobRegisterPopup(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &-head {
    cursor: pointer;
    position: relative;
    color: #333;
    border-bottom: 1px solid #a2a2a2;
    background-color: #fff;
    display: block;
    padding: 14px 20px;
    transition: background-color $trans, color $trans, border-color $trans;

    @include respondTo(600px) {
      padding: 10px;
    }

    &.active,
    &:hover {
      background-color: #EEF0F3;
      color: #333;
    }
  }

  &-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    $w: 12px;
    $h: 1px;
    $bg: #082C23;
    $transition: 0.25s all cubic-bezier(0.17, 0.67, 0.09, 0.97);

    .wrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .toggle {
      width: $w;
      height: $h;
      background: $bg;
      position: relative;
      transition: $transition;

      &.open::after {
        transform: rotate(90deg);
        opacity: 1;
      }

      &.open {
        transform: rotate(90deg);
      }

      &::after {
        content: "";
        width: $w;
        transition: $transition;
        transition-delay: 0.1s;
        height: $h;
        position: absolute;
        opacity: 0;
        background: $bg;
      }
    }
  }

  &-content {
    font-size: 14px;
    line-height: 20px;
    padding: 20px 35px;
    background-color: $white;
    //border-left: 1px solid #a2a2a2;
    border-bottom: 1px solid #a2a2a2;
    //border-right: 1px solid #a2a2a2;

    p {
      font-size: 14px;
      line-height: 20px;
    }

    a {
      color: $black;
    }
    h3{
      margin: 10px 0 15px;
      font-size: 16px;
      line-height: 1.2;
      @include respondTo(600px) {
        font-size: 14px;
      }
    }
    ul{
      list-style-type: circle;
      padding-left: 15px;
    }
    @include respondTo(600px) {
      padding: 10px 15px;
    }
  }

  &-title {
    font-size: 14px;
    line-height: 18px;
    max-width: 95%;
    text-transform: uppercase;
    @include respondTo(600px) {
      font-size: 12px;
      line-height: 14px;
      max-width: 92%;
    }
  }

  &-item {
    font-size: 14px;
    line-height: 22px;
    @include respondTo(600px){
      font-size: 12px;
      line-height: 18px;
    }
  }
  &-action{
    margin-top: 20px;
    text-align: right;
    a{
      text-align: center;
      position: relative;
      display: inline-block;
      padding: 15px;
      border: 0;
      outline: none;
      cursor: pointer;
      user-select: none;
      min-width: 150px;
      background: #00b764;
      transition: background-color 0.3s ease;
      text-decoration: none;
      color: $white;
      border-radius: 8px;
      @include respondTo(600px){
        padding: 10px;
        font-size: 14px;
      }
      &:hover{
        border-bottom: none;
        background-color: #00924f;
      }
    }
  }
}
</style>
