const state = {
  mainSlider: null,
  mainSliderLoading: false,
  isShowJobRegisterPopup: false
}

const getters = {
  mainSlider: state => state.mainSlider,
  mainSliderLoading: state => state.mainSliderLoading,
  isShowJobRegisterPopup: state => state.isShowJobRegisterPopup
}

const mutations = {
  changeShowJobsPopup (state, status) {
    state.isShowJobRegisterPopup = status
  }
}

export default {
  state,
  getters,
  mutations
}
