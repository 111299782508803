import { CardNumber, CardExpiry, CardCvc } from 'vue-stripe-elements-plus'
import BaseInput from '@/components/atoms/_BaseInput.vue'
import StripeInput from '@/components/atoms/StripeInput.vue'
import BaseButton from '@/components/atoms/_BaseButton.vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { get } from 'lodash'

const STRIPE_API_KEY = process.env.VUE_APP_STRIPE_API_KEY

export default {
  name: 'stripe-form',
  data () {
    return {
      name: '',
      number: false,
      expiry: false,
      cvc: false,
      submitting: false,
      errorResp: null,
      package_id: null,
      special_offer_id: null,
      stripeOptions: {
        style: {
          base: {
            fontFamily: "'Montserrat', sans-serif",
            color: '#fdfdfd',
            height: '40px',
            fontSize: '16px',
            '::placeholder': {
              color: '#fdfdfd'
            }
          }
        }
      }
    }
  },
  components: {
    BaseInput,
    StripeInput,
    BaseButton,
    CardNumber,
    CardExpiry,
    CardCvc
  },
  validations: {
    name: { required },
    number: {
      checked: value => value === true
    },
    expiry: {
      checked: value => value === true
    },
    cvc: {
      checked: value => value === true
    }
  },
  stripeApiKey: STRIPE_API_KEY,
  computed: {
    ...mapGetters({
      offerData: 'auth/offerData',
      offerDataLoading: 'auth/offerDataLoading'
    }),
    isComplete () {
      return this.number && this.expiry && this.cvc
    }
  },
  watch: {
    number: 'moveFocus',
    expiry: 'moveFocus',
    cvc: 'moveFocus',
    offerData: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.package_id = newVal.package_id
          this.special_offer_id = newVal.id
        }
      }
    }
  },
  methods: {
    ...mapActions({
      buyPackage: 'packages/buy'
    }),
    moveFocus () {
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus()
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus()
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus()
        } else if (!this.number) {
          this.$refs.cardNumber.focus()
        }
      }
    },
    async onSubmit () {
      this.$v.$touch()
      this.errorResp = null
      if (this.$v.$error) return
      this.submitting = true
      try {
        // eslint-disable-next-line camelcase
        const { name, package_id, special_offer_id } = this
        await this.buyPackage({ name, package_id, special_offer_id })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (error) {
        this.submitting = false
        const stripeError = get(error, 'response.data.error')
        if (stripeError && Object.keys(stripeError).length) {
          const errTitle = Object.keys(stripeError)[0]
          this.errorResp = `${errTitle}: ${stripeError[errTitle].message}`
        } else {
          this.errorResp = this.$t('error')
        }
        if (error.response.data.data.special_offer_id) {
          this.$toasted.error(this.$t('maximumOffersCountUse'))
          this.errorResp = this.$t('maximumOffersCountUse')
        }
      }
    },
    isPackagePaid (pkg) {
      return pkg.price !== '0.00' && pkg.price !== '0.00 $' && pkg.price !== '0'
    },
    async setPackage () {
      this.submitting = true
      try {
        // eslint-disable-next-line
        const { package_id, special_offer_id } = this
        await this.buyPackage({ package_id, special_offer_id })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (error) {
        this.submitting = false
        if (error.response.data.data.special_offer_id) {
          this.$toasted.error(this.$t('maximumOffersCountUse'))
        }
      }
    }
  }
}
